import './style.css'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Card, CardContent, Pagination } from '@mui/material';
import Badge from 'react-bootstrap/Badge';
import { Form } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { CirclesWithBar } from 'react-loader-spinner'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CachedIcon from '@mui/icons-material/Cached';
import ResetIcon from '@mui/icons-material/Restore';
import { RepeatTableModal } from './RepeatModal'
import { DataComments } from "./partials/DataComments";
import { DataLog } from "./partials/DataLog";
import Handsontable from "handsontable";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/scss/bootstrap.scss';
import "handsontable/dist/handsontable.min.css";
import "pikaday/css/pikaday.css";
import 'handsontable/dist/handsontable.full.css';
import { DataMap, loadMapData } from './partials/DataMap';
import { DataMedia } from './partials/DataMedia';
import { DataGraph } from './partials/DataGraph';
import { repeatedData } from '../../queries'
import moment from "moment";
let user = getLocalUser();
let stock_info_fetched = [];
let valueData = 0;
let uneditable = ['uuid', 'metaId', '__id', '_id', '*meta-instance-id*', 'FormID', 'form_id', 'form', 'deviceid', 'deviceId', 'ID', '__ID', 'Form', 'FormID', 'formId', 'FormId', 'Device', 'device_id', 'form_group', 'odk_form_id', 'meta.instanceID', 'client_id', 'createdAt', 'updatedAt', 'modifiedAt', 'created_at', 'updated_at', 'modified_at'];
let updateData = {};
let hot = '';
let allFilters = [];
let initialFilters = [];
let filters = [];
let projectFilters = [];
let qcFlags = [];
let sites = [];
let allSites = [];
let projects = [];
let clients = [];
let formGroups = [];
let monitors = [];
let imageFields = [];
let mapData = [];
let columnsInitial = [];
let columnsDataTableInitial = [];
let editableDataOptions = [];
let taskStatuses = [];
let notificationRecipients = [];
let taskPriorities = [];
let multipleDataField = ['filter_qc_flag', 'filter_monitor', 'filter_column', 'filter_param', 'filter_form', 'filter_site', 'filter_form_groups', 'filter_client', 'filter_project', 'useremail', 'urgency', 'filter_editor', 'filter_commenter', 'filter_image_fields', 'filter_task_recipient', 'filter_task_priority', 'notification_recipient'];
let tableSettingsParams;
let repeatColumns = [];
let commenter = [];
let task_recipients_select = [];
let task_priorities_select = [];
let editors = [];
jQuery.fn.modal.Constructor.prototype.enforceFocus = function () { };
jQuery.fn.modal.Constructor.prototype._enforceFocus = function () { };
jQuery.fn.modal.Constructor.prototype._initializeFocusTrap = function () { return { activate: function () { }, deactivate: function () { } } };
import { a11yProps, TabPanel, isEmpty, Notify, getLocalUser, setLocalStorage, getLocalStorage, clearCache, getOptionsLength, getSelectedLength, commentsRenderer, disableSingleSite, checkIfValidUserTypes, sortObject, getCurrentTimeZone, checkIfWriteAccess, checkIfProjectAccess, checkIfClientAccess, checkIfAdminAccess, processSingleSiteFromMedia, initRefreshGraphMediaAction, getFilterFlag, oldValuesLength, oldValues, newValues, returnVariables, postRequest, generateFilterCountAndAvailability } from './common-functions.js'
const select2InitializationForComment = (isDisabled = false) => {

    jQuery('.select2-init-comment').each(function () {
        var $_this = jQuery(this);
        $_this.hide();
        $_this.addClass('d-none');
        if (!isDisabled) {
            $_this.removeProp('disabled');
            $_this.removeAttr('disabled');
        }
        if ($_this.parent().hasClass('multiselect-native-select')) {
            $_this.multiselect('destroy');
        }
        $_this.multiselect({
            numberDisplayed: 1,
            includeSelectAllOption: true,
            enableFiltering: true,
            filterPlaceholder: 'Search for something...',
            enableCaseInsensitiveFiltering: true,
            maxHeight: 310,
            dropUp: false,
        });
        if (isDisabled) {
            $_this.multiselect('disable');
        } else {
            $_this.multiselect('enable');
        }
    });
}

// Initialize datatable
function handsontableInitialization() {
    let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
    let containerForTable = document.getElementById("handsontableContainer");
    let hiddenColumnsPlugin = '';
    if (containerForTable) {
        if (jQuery('.ht_master').length > 0 && hot) {
            hot.updateSettings(tableSettingsParams);
            hiddenColumnsPlugin = hot.getPlugin('hiddenColumns');
        } else {
            let tempHot = new Handsontable(containerForTable, tableSettingsParams);
            hot = tempHot;
            hiddenColumnsPlugin = hot.getPlugin('hiddenColumns');
        }
        hot.getPlugin('Filters').clearConditions();
        hot.getPlugin('Filters').filter();
        hiddenColumnsPlugin.enablePlugin();
        // hiddenColumnsPlugin.hideColumn(1, 4, 5);
        // hiddenColumnsPlugin.hideColumn(1);
        hot.render();
        callCommentFunctions();
        hot.addHook('afterCreateRow', (row, amount) => {
            callCommentFunctions();
        });
        hot.addHook('modifyColWidth', (width, column) => {
            callCommentFunctions();
        });
    }
    jQuery('.loader-container').addClass('d-none');
}

function callCommentFunctions() {
    setTimeout(() => {
        initCommentsModal();
    }, 1000);
}

export function initCommentsModal() {
    jQuery('.back-link-task').off('click');
    jQuery('.back-link-task').on('click', function (e) {
        e.preventDefault();
        jQuery('#exampleAddCommentModal').find('.modal-body').removeClass('d-none');
        jQuery('.add-link-task').removeClass('d-none');
        jQuery(this).addClass('d-none');
        jQuery('.tasks-container').removeClass('d-none')
        jQuery('.tasks-add-container').addClass('d-none')
        jQuery('.tasks-add-new-user-container').addClass('d-none')

        setTimeout(() => {
            select2InitializationForComment();
        }, 100);
    })
    jQuery('.add-link-task').off('click');
    jQuery('.add-link-task').on('click', function (e) {
        e.preventDefault();
        jQuery('.update-comment-button').addClass('d-none');
        jQuery('.add-comment-button').removeClass('d-none');
        jQuery('#exampleAddCommentModal').find('.modal-body').addClass('d-none');
        jQuery('.back-link-task').removeClass('d-none');
        jQuery(this).addClass('d-none');
        jQuery('.tasks-container').addClass('d-none')
        jQuery('.tasks-add-container').removeClass('d-none')
        jQuery('.tasks-add-new-user-container').addClass('d-none')
        jQuery('.comments-parent-table-container').addClass('d-none');
        jQuery('.tasks-add-container').find('.comments-table-container').find('select').removeAttr('readonly').removeAttr('disabled')
        jQuery('.tasks-add-container').find('.comments-table-container').find('textarea').removeAttr('readonly').removeAttr('disabled')
        jQuery('.tasks-add-container').find('.comments-table-container').find('input').removeAttr('readonly').removeAttr('disabled')
        jQuery('.tasks-add-container').find('.comments-table-container').find('button').removeAttr('readonly').removeAttr('disabled')

        jQuery('select.row_notification_recipient').val('')
        jQuery('select.row_comment_status').val('')
        jQuery('select.row_task_priority').val('')
        jQuery('.row_task_due_date').val('')
        jQuery('.comment-text').val('')

        setTimeout(() => {
            select2InitializationForComment();
        }, 100);
    })
    jQuery('.add-comment-button').off('click');
    jQuery('.add-comment-button').on('click', function () {
        let commentText = jQuery('.comment-text').val();
        let qcFlagText = jQuery('select.row_qc_flag').val();
        let taskStatusText = jQuery('select.row_comment_status').val();
        let receipientText = jQuery('select.row_notification_recipient').val();
        let priorityText = jQuery('select.row_task_priority').val();
        let dueDateText = jQuery('.row_task_due_date').val();
        let id = jQuery('.row_instance_id').html();
        var tempObj = {
            'meta_instanceID': id, 'task': commentText, 'qc_flag': qcFlagText, 'task_status': taskStatusText, 'due_date': dueDateText, 'notification_recipients': receipientText, 'task_priority': priorityText,
        };
        if (checkIfWriteAccess() && commentText !== '' && taskStatusText !== '') {
            jQuery('.loader-container').removeClass('d-none');
            postRequest(`tasks/add/`, tempObj).then(res1 => {
                jQuery('select.row_comment_status').val('')
                jQuery('select.row_notification_recipient').val('')
                loadTasksFromApi(id, true, true, 'Task Added Successfully.', false);
            });
        } else {
            Notify("error", "Please enter the required fields. ");
        }
    });
    jQuery('.update-comment-add-user-button').off('click');
    jQuery('.update-comment-add-user-button').on('click', function () {
        let receipientText = jQuery('select.add_row_notification_recipient').val();
        let id = jQuery('.row_instance_id').html();
        let task_id = jQuery('.comment_id').data('comment-id');
        var tempObj = { 'meta_instanceID': id, 'notification_recipients': receipientText, '_id': task_id };
        if (checkIfWriteAccess()) {
            jQuery('.loader-container').removeClass('d-none');
            postRequest(`tasks/update/`, tempObj).then(res1 => {
                jQuery('select.row_comment_status').val('')
                jQuery('select.row_notification_recipient').val('')
                loadTasksFromApi(id, true, true, 'Your task from DE Data Portal has been updated', false);
            });
        } else {
            Notify("error", "Please enter the required fields. ");
        }
    });
    jQuery('.update-task-qc-flag').off('change');
    jQuery('.update-task-qc-flag').on('change', function () {
        let id = jQuery('.row_instance_id').html();
        let task_id = jQuery('.comment_id').data('comment-id');
        var tempObj = { 'meta_instanceID': id, 'qc_flag': $(this).val(), '_id': task_id };
        if (checkIfWriteAccess()) {
            jQuery('.loader-container').removeClass('d-none');
            postRequest(`tasks/update/`, tempObj).then(res1 => {
                jQuery('select.row_comment_status').val('')
                jQuery('select.row_notification_recipient').val('')
                loadTasksFromApi(id, true, true, 'Your task from DE Data Portal has been updated', false);
            });
        } else {
            Notify("error", "Please enter the required fields. ");
        }
    });
    jQuery('.update-comment-button').off('click');
    jQuery('.update-comment-button').on('click', function () {
        let commentText = jQuery('.comment-text').val();
        let qcFlagText = jQuery('select.row_qc_flag').val();
        let taskStatusText = jQuery('select.row_comment_status').val();
        let receipientText = jQuery('select.row_notification_recipient').val();
        let priorityText = jQuery('select.row_task_priority').val();
        let dueDateText = jQuery('.row_task_due_date').val();
        let id = jQuery('.row_instance_id').html();
        let task_id = jQuery('.comment_id').attr('data-comment-id');
        var tempObj = {
            'meta_instanceID': id,
            'task': commentText,
            'qc_flag': qcFlagText,
            'task_status': taskStatusText,
            'due_date': dueDateText,
            'notification_recipients': receipientText,
            'task_priority': priorityText,
            '_id': task_id,
        };

        var current_data_temp = $('[data-comment-id="' + task_id + '"]');
        if (current_data_temp?.attr('data-task-status') == 'Complete') {
            tempObj = {
                '_id': task_id,
                'meta_instanceID': id,
                'task_status': taskStatusText
            };
        }
        if (checkIfWriteAccess() && qcFlagText != '' && commentText !== '' && taskStatusText !== '') {
            jQuery('.loader-container').removeClass('d-none');
            postRequest(`tasks/update/`, tempObj).then(res1 => {
                if (current_data_temp?.attr('data-task-status') == 'Complete') {
                    loadTasksFromApi(id, true, true, 'Your task from DE Data Portal has been updated', false, task_id, true);
                } else if (current_data_temp?.attr('data-task-status') != 'Complete' && taskStatusText == 4) {
                    loadTasksFromApi(id, true, true, 'Your task from DE Data Portal has been updated', false, task_id, true);
                } else {
                    loadTasksFromApi(id, true, true, 'Your task from DE Data Portal has been updated', false, task_id, true);
                }
            });
        } else {
            Notify("error", "Please enter the required fields. ");
        }
    });
    jQuery('.add-task-comment-button').off('click');
    jQuery('.add-task-comment-button').on('click', function () {
        let receipientText = jQuery('select.add_row_notification_recipient_comment').val();
        let commentText = jQuery('.task-comment-text').val();
        commentText = commentText?.trim();
        let id = jQuery('.row_instance_id').html();
        let task_id = jQuery('.comment_id').data('comment-id');
        var tempObj = { 'meta_instanceID': id, 'comment': commentText, 'notification_recipients': receipientText, 'task_id': task_id };
        if (checkIfWriteAccess() && commentText !== '') {
            jQuery('.loader-container').removeClass('d-none');
            jQuery('.task-comment-text').val('');
            postRequest(`task-comments/add/`, tempObj).then(res1 => {
                loadTaskCommentFromApi(id, true, true, 'Task Comment Added Successfully.', false, task_id);
            });
        } else {
            Notify("error", "Please enter the required fields. ");
        }
    });
    jQuery('.add-comment-dialog').off('click');
    jQuery('.add-comment-dialog').on('click', function () {
        jQuery('.comments-table').html('');
        jQuery('.total-comments').html('');
        jQuery('.comments-site-name').html('');
        loadTasksFromApi(jQuery(this).data('id'), false, false, '', false, '', true, $(this).hasClass('no-tasks'));
    })
    jQuery('.add-task-comment-dialog').off('click');
    jQuery('.add-task-comment-dialog').on('click', function () {
        jQuery('.comments-table').html('');
        jQuery('.total-comments').html('');
        jQuery('.comments-site-name').html('');
        loadTasksFromApi(jQuery(this).data('id'), false, false, '', false, jQuery(this).data('task-id'));
    })
    jQuery('.close-comment').off('click');
    jQuery('.close-comment').on('click', function () {
        jQuery('#exampleAddCommentModal').modal('hide')
    });
}

function loadTaskCommentFromApi(id, forceLoad = false, showSuccessMessage = false, message = '', isDeleted = false, taskId = '') {
    $('.reload-comments-data').click();
    $('.edit-task').find('[data-comment-id="' + taskId + '"]').click();
}

function loadTasksFromApi(
    id,
    forceLoad = false,
    showSuccessMessage = false,
    message = '',
    isDeleted = false,
    taskId = '',
    reload = true,
    noTasks = false
) {
    jQuery('.loader-container').removeClass('d-none');
    if (noTasks) {
        jQuery('.comments-container').addClass('d-none');
        jQuery('.no-record-comments-container-modal').removeClass('d-none');
        setTimeout(() => {
            jQuery('.add-link-task').click();
            setTimeout(() => {
                jQuery('.back-link-task').addClass('d-none')
                jQuery('#exampleAddCommentModal').find('.modal-body').addClass('d-none');
            }, 10);
        }, 10);
    }
    var tempObj = { 'meta.instanceID': [id], 'meta_instanceID': id, };
    var details = {};
    var $_this = jQuery('select.row_notification_recipient');
    var attr_name = $_this.attr('name');
    if (multipleDataField.find(rows => rows == attr_name)) {
        $_this.hide();
        $_this.addClass('d-none');
        if (jQuery('.multiselect-native-select').length > 0) {
            $_this.multiselect('destroy');
        }
        $_this.multiselect({
            buttonText: function (options, event) {
                var selectedLength = options.length;
                var element_name = $(event).attr('name');
                var optionsLength = $(event).find('option').length;
                if (optionsLength == 0) {
                    return 'No Options';
                }
                else if (options.length == 0) {
                    if (element_name == 'filter_column') {
                        return 'All Selected';
                    }
                    return 'None selected';
                }
                else if (selectedLength > 0 && selectedLength == optionsLength) {
                    return `${selectedLength} Selected`;
                }
                else if (optionsLength == 0) {
                    return 'No Options';
                }
                else if (options.length > 0) {
                    return options.length + ' selected';
                }
                else {
                    var selected = '';
                    options.each(function () {
                        selected += $(this).text() + ', ';
                    });
                    return selected.substr(0, selected.length - 2);
                }
            },
            numberDisplayed: 1,
            includeSelectAllOption: true,
            enableFiltering: true,
            filterPlaceholder: 'Search for something...',
            enableCaseInsensitiveFiltering: true,
            maxHeight: 310,
            dropUp: false
        });
    }

    if (!forceLoad && stock_info_fetched.find(rows => rows['meta.instanceID'] == tempObj['meta.instanceID'][0])) {
        details = stock_info_fetched.find(rows => rows['meta.instanceID'] == tempObj['meta.instanceID'][0]);
        loadDataInCommentModal(details, showSuccessMessage, message, isDeleted, taskId, reload);
    } else {
        var detailsIndex = stock_info_fetched.findIndex(rows => rows['meta.instanceID'] == tempObj['meta.instanceID'][0]);
        if (detailsIndex >= 0) {
            stock_info_fetched.splice(detailsIndex - 1)
        }
        tempObj['tz'] = getCurrentTimeZone();
        postRequest(`map/info/`, tempObj).then(res => {
            let tempObjForTasks = tempObj;
            postRequest(`tasks/get-submission-tasks/`, tempObjForTasks).then(res1 => {
                let commentsData = res1.data.data;
                details = res.data.data.message[0];
                details['total'] = commentsData?.total;
                details['totalComments'] = commentsData?.total;
                details['comments'] = commentsData?.tasks;
                stock_info_fetched.push(details);
                loadDataInCommentModal(details, showSuccessMessage, message, isDeleted, taskId, reload);
            });
        });
    }
}

let completedArr = "";
function loadDataInCommentModal(details, showSuccessMessage = false, message = '', isDeleted = false, taskId = '', reload = true) {
    jQuery('.row_instance_id').html(details['meta.instanceID']);
    jQuery('.row_site').html(details['site']);
    jQuery('.row_coordinates').html(details['latitude'] + ',' + details['longitude']);
    jQuery('.row_submitted').html(details['datetime']);
    jQuery('.row_project').html(details['project']);
    jQuery('.row_monitor').html(details['monitor_phone']);
    jQuery('td.row_qc_flag').html(details['qc_flag']);
    var tempQcFlag = qcFlags?.find(rows => rows.label == details['qc_flag']);
    jQuery('select.row_qc_flag').val(tempQcFlag?.value);
    jQuery('.row_form').html(details['odk_form_id']);
    completedArr = "";
    $('.information-data').html('');
    $('.in-progress-data').html('');
    $('.completed-data').html('');
    for (let tempCommentIndex1 = 0; tempCommentIndex1 < details?.comments['complete']?.length; tempCommentIndex1++) {
        const element = details?.comments?.complete[tempCommentIndex1];
        prepareHtmlForTaskByStatus(element)
    }

    for (let tempCommentIndex1 = 0; tempCommentIndex1 < details?.comments['informationOnly']?.length; tempCommentIndex1++) {
        const element = details?.comments?.informationOnly[tempCommentIndex1];
        prepareHtmlForTaskByStatus(element)
    }

    for (let tempCommentIndex1 = 0; tempCommentIndex1 < details?.comments['pending_inProgress_question']?.length; tempCommentIndex1++) {
        const element = details?.comments?.pending_inProgress_question[tempCommentIndex1];
        prepareHtmlForTaskByStatus(element)
    }

    // $('.completed-data').html(completedArr);
    $('.datepicker').datepicker({
        autoClose: true,
        onSelect: function (dateText, inst) {

        }
    }).on('changeDate', function (ev) {
        var $_this = jQuery(ev.target);
        var due_date = new Date(ev.date);
        due_date = due_date.toUTCString();
        var tempObj = { '_id': $_this.data('comment-id'), 'meta_instanceID': $_this.data('instance-id'), 'due_date': due_date, };
        jQuery('.loader-container').removeClass('d-none');
        postRequest(`tasks/update/`, tempObj).then(res1 => {
            $('.datepicker').hide();
            loadTasksFromApi($_this.data('instance-id'), true, true, 'Your task from DE Data Portal has been updated.', false);
        });
    });
    if (!checkIfWriteAccess()) {
        $('#exampleAddCommentModal').find('.modal-footer').addClass('d-none');
    }
    jQuery('.total-comments').html(' (' + details?.totalComments + ')')
    jQuery('.comments-site-name').html(' <span class="">' + details?.site + '</span>')
    jQuery('.comments-table-parent-container').addClass('d-none');
    if (details['totalComments'] > 0) {
        jQuery('.comments-container').removeClass('d-none');
        jQuery('.no-record-comments-container-modal').addClass('d-none');
    } else {
        jQuery('.comments-container').addClass('d-none');
        jQuery('.no-record-comments-container-modal').removeClass('d-none');
        setTimeout(() => {
            jQuery('.add-link-task').click();
            setTimeout(() => {
                jQuery('.back-link-task').addClass('d-none')
                jQuery('#exampleAddCommentModal').find('.modal-body').addClass('d-none');
            }, 10);
        }, 10);
    }
    jQuery.fn.modal.Constructor.prototype.enforceFocus = function () { };
    jQuery.fn.modal.Constructor.prototype._enforceFocus = function () { };
    jQuery.fn.modal.Constructor.prototype._initializeFocusTrap = function () { return { activate: function () { }, deactivate: function () { } } };

    jQuery('#exampleAddCommentModal').modal('show');
    // jQuery('.loader-container').addClass('d-none');
    if (showSuccessMessage && message) {
        jQuery('.task-comment-text').val('');
        jQuery('.task-comment-text').text('');
        Notify("info", message);
    }
    tableSettingsParams?.data?.map(rows => {
        var tempIndex = tableSettingsParams.colHeaders.findIndex(rows => rows == 'meta.instanceID');
        var tempQcFlagIndex = tableSettingsParams.colHeaders.findIndex(rows => rows == 'qc_flag');
        var tempCommentsIndex = tableSettingsParams.colHeaders.findIndex(rows => rows == 'tasks');
        if (rows[tempIndex] == details['meta.instanceID']) {
            rows[tempQcFlagIndex] = tempQcFlag?.label;
            if (details['totalComments']) {
                rows[tempCommentsIndex] = details['totalComments'];
            }
        }
        return [
            ...rows
        ]
    })
    $('[data-toggle="popover"]').popover({
        html: true,
        container: 'body',
        sanitize: false,
        placement: 'bottom',
        content: function () {
            var content = $(this).attr("data-popover-content");
            return $(content).html();
        },
        title: function () {
            return '';
        }
    });

    $('[data-toggle="popover"]').on('shown.bs.popover', function () {
        var currentTop = parseInt($(this).css('top'));
        var currentLeft = parseInt($(this).css('left'));
        currentLeft = isNaN(currentLeft) ? 0 : currentLeft;
        $(this).css({
            top: (currentTop + 100) + 'px',
            left: (currentLeft - 180) + 'px'
        });
        $('.popover-arrow').css({
            left: (currentLeft + 170) + 'px'
        });
    })

    $('.circle-avatar-plain').off('click');
    $('.circle-avatar-plain').on('click', function (e) {
        e.preventDefault();
        var $_this = $(this);
        var htmlForEdit = prepareHtmlForEdit($_this);
        Swal.fire({
            title: 'Edit Recipient',
            html: htmlForEdit,
            customClass: 'swal-custom-width',
            showCancelButton: true,
            confirmButtonColor: "#0b5ed7",
            confirmButtonText: 'Update',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return {
                    'notification_recipients': jQuery('select#update_notification_recipient').val(),
                }
            },
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    resolve('You need to add details.')
                })
            }
        }).then((result) => {
            if (result.isConfirmed) {
                var tempObj = {
                    '_id': $_this.data('comment-id'),
                    'meta_instanceID': $_this.data('instance-id'),
                    ...result.value
                };
                postRequest(`tasks/update/`, tempObj).then(res1 => {
                    loadTasksFromApi($_this.data('instance-id'), true, true, 'Your task from DE Data Portal has been updated.', false);
                });
            }
        })
    });

    $('.edit-task-calendar').off('click');
    $('.edit-task-calendar').on('click', function (e) {
        var taskId = $(this).attr('data-comment-id');
        $('.edit-task').find('[data-comment-id="' + taskId + '"]').click();
    });
    $('.edit-task').off('click');
    $('.edit-task').on('click', function (e) {
        jQuery('.comments-parent-table-container').removeClass('d-none');
        jQuery('.comments-parent-table-container').find('.comments-table-container:first-child').removeClass('d-none');
        jQuery('.comments-parent-table-container').find('.add-comment-container').removeClass('d-none');
        jQuery('.tasks-add-container').find('.comments-table-container').find('select').removeAttr('readonly').removeAttr('disabled')
        jQuery('.tasks-add-container').find('.comments-table-container').find('textarea').removeAttr('readonly').removeAttr('disabled')
        jQuery('.tasks-add-container').find('.comments-table-container').find('input').removeAttr('readonly').removeAttr('disabled')
        jQuery('.tasks-add-container').find('.comments-table-container').find('button').removeAttr('readonly').removeAttr('disabled')
        jQuery('.add-comment-button').addClass('d-none');
        jQuery('.update-comment-button').removeClass('d-none');
        var $_this = $(this).find('button');
        var current_data = $_this.data();
        var notificationUsers = current_data.notificationRecipient.split(',');
        var notificationUsersIds = [];
        var notificationUsersIdsForComment = [];
        var current_user = getLocalUser();
        notificationUsers?.map(rows => {
            var tempRecipient = notificationRecipients?.find(row => row.label == rows);
            if (tempRecipient?.value) {
                notificationUsersIds.push(tempRecipient?.value)
                if (tempRecipient?.label != current_user?.name) {
                    notificationUsersIdsForComment.push(tempRecipient?.value);
                }
            }
        })
        $('.tasks-add-container').find('input.comment_id').val(current_data.commentId);
        $('.tasks-add-container').find('input.comment_id').attr('data-comment-id', current_data.commentId);
        jQuery('select.row_notification_recipient').val(notificationUsersIds)
        jQuery('select.add_row_notification_recipient_comment').val(notificationUsersIdsForComment)
        jQuery('select.row_comment_status').val(taskStatuses.find(rows => rows.label == current_data.taskStatus)?.value)
        jQuery('select.row_task_priority').val(taskPriorities.find(rows => rows.label == current_data.taskPriority)?.value)
        jQuery('.row_task_due_date').val(moment(current_data.dueDate).format('YYYY-MM-DD'))
        jQuery('.comment-text').val(current_data.value)
        var tempObj = { 'task_id': current_data.commentId, };
        jQuery('.loader-container').removeClass('d-none');
        postRequest(`task-comments/get-task-comments/`, tempObj).then(res1 => {
            var comments = res1?.data?.data?.comments;
            $('.task-comments').html('');
            if (comments?.length > 0) {
                comments?.map(rows => {
                    let tempTemplate = $('#templateForTaskComment').clone();
                    tempTemplate.find('.date').html(new Date(rows.created_at)?.toDateString() + ' ' + new Date(rows.created_at)?.toLocaleTimeString());
                    tempTemplate.find('.name').html(rows?.commenter);
                    var str = rows?.commenter;
                    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
                    var acronym = matches.join(''); // JSON

                    tempTemplate.find('.circle-avatar').data('tooltip', acronym).attr('title', acronym);
                    var userClass = notificationRecipients.find(rowUser => rowUser?.label == rows?.commenter)?.backgroundColor;
                    tempTemplate.find('.circle-avatar').html(acronym);
                    tempTemplate.find('.circle-avatar').addClass(userClass);
                    tempTemplate.find('.comment').html(rows?.comment);

                    var userHtml = '';
                    let reciepients = rows?.notification_recipients?.initials;
                    if (reciepients) {
                        for (let recipientIndex = 0; recipientIndex < reciepients.length; recipientIndex++) {
                            const name = rows?.notification_recipients?.full_name[recipientIndex];
                            const initial = reciepients[recipientIndex];
                            if (name.trim() != '') {
                                var userClass = notificationRecipients.find(rowUser => rowUser?.label == name)?.backgroundColor;
                                userHtml += '<div class="circle-avatar cursor-pointer ' + userClass + ' " title="' + name + '" data-tooltip="' + name + '">' + initial + '</div>';
                            }
                        }
                        tempTemplate.find('.card-footer').removeClass('d-none');
                        tempTemplate.find('.user').html(userHtml);
                    }
                    $('.task-comments').append(tempTemplate[0]?.firstChild);
                    setTimeout(() => {
                        jQuery('.loader-container').addClass('d-none');
                    }, 1000);
                })
            } else {
                setTimeout(() => {
                    jQuery('.loader-container').addClass('d-none');
                }, 1000);
            }

            jQuery('.add-link-task').addClass('d-none')
            jQuery('#exampleAddCommentModal').find('.modal-body').addClass('d-none');
            jQuery('.back-link-task').removeClass('d-none')
            jQuery('.tasks-container').addClass('d-none')
            jQuery('.tasks-add-container').removeClass('d-none')

            setTimeout(() => {
                select2InitializationForComment();
            }, 100);
        });
    });
    $('.view-completed-task').off('click');
    $('.view-completed-task').on('click', function (e) {
        jQuery('.comments-parent-table-container').removeClass('d-none');
        jQuery('.comments-parent-table-container').find('.comments-table-container:first-child').addClass('d-none');
        jQuery('.comments-parent-table-container').find('.add-comment-container').addClass('d-none');
        jQuery('.comments-table-container').find('select').attr('readonly', 'readonly').attr('disabled', 'disabled')
        jQuery('.comments-table-container').find('select.comment_status_comment').removeAttr('readonly').removeAttr('disabled')
        jQuery('.comments-table-container').find('textarea').attr('readonly', 'readonly').attr('disabled', 'disabled')
        jQuery('.comments-table-container').find('input').attr('readonly', 'readonly').attr('disabled', 'disabled')
        jQuery('.comments-table-container').find('button').attr('readonly', 'readonly').attr('disabled', 'disabled')
        jQuery('.add-comment-button').addClass('d-none');
        var $_this = $(this).find('button');
        var current_data = $_this.data();
        var notificationUsers = current_data.notificationRecipient.split(',');
        var notificationUsersIds = [];
        notificationUsers?.map(rows => {
            notificationUsersIds.push(notificationRecipients?.find(row => row.label == rows)?.value)
        })
        jQuery('select.row_notification_recipient').val(notificationUsersIds)
        jQuery('select.row_comment_status').val(taskStatuses.find(rows => rows.label == current_data.taskStatus)?.value)
        jQuery('select.row_task_priority').val(taskPriorities.find(rows => rows.label == current_data.taskPriority)?.value)
        jQuery('.row_task_due_date').val(moment(current_data.dueDate).format('YYYY-MM-DD'))
        jQuery('.comment-text').val(current_data.value)

        $('.tasks-add-container').find('input.comment_id').val(current_data.commentId);
        $('.tasks-add-container').find('input.comment_id').attr('data-comment-id', current_data.commentId);

        var tempObj = { 'task_id': $_this.data('comment-id'), };
        jQuery('.loader-container').removeClass('d-none');
        postRequest(`task-comments/get-task-comments/`, tempObj).then(res1 => {
            var comments = res1?.data?.data?.comments;
            $('.task-comments').html('');
            if (comments?.length > 0) {
                comments?.map(rows => {
                    let tempTemplate = $('#templateForTaskComment').clone();
                    tempTemplate.find('.date').html(new Date(rows.created_at)?.toDateString() + ' ' + new Date(rows.created_at)?.toLocaleTimeString());
                    tempTemplate.find('.name').html(rows?.commenter);
                    var str = rows?.commenter;
                    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
                    var acronym = matches.join(''); // JSON

                    tempTemplate.find('.circle-avatar').data('tooltip', acronym).attr('title', acronym);
                    var userClass = notificationRecipients.find(rowUser => rowUser?.label == rows?.commenter)?.backgroundColor;
                    tempTemplate.find('.circle-avatar').html(acronym);
                    tempTemplate.find('.circle-avatar').addClass(userClass);
                    tempTemplate.find('.comment').html(rows?.comment);

                    var userHtml = '';
                    let reciepients = rows?.notification_recipients?.initials;
                    if (reciepients) {
                        for (let recipientIndex = 0; recipientIndex < reciepients.length; recipientIndex++) {
                            const name = rows?.notification_recipients?.full_name[recipientIndex];
                            const initial = reciepients[recipientIndex];
                            if (name.trim() != '') {
                                var userClass = notificationRecipients.find(rowUser => rowUser?.label == name)?.backgroundColor;
                                userHtml += '<div class="circle-avatar cursor-pointer ' + userClass + ' " title="' + name + '" data-tooltip="' + name + '">' + initial + '</div>';
                            }
                        }
                        tempTemplate.find('.card-footer').removeClass('d-none');
                        tempTemplate.find('.user').html(userHtml);
                    }

                    $('.task-comments').append(tempTemplate[0]?.firstChild);
                })
            }

            jQuery('.loader-container').addClass('d-none');
            jQuery('.add-link-task').addClass('d-none')
            jQuery('#exampleAddCommentModal').find('.modal-body').addClass('d-none');
            jQuery('.back-link-task').removeClass('d-none')
            jQuery('.tasks-container').addClass('d-none')
            jQuery('.tasks-add-container').removeClass('d-none')

            setTimeout(() => {
                select2InitializationForComment(true);
            }, 100);
        });
    });

    $('.card-radio-text').off('click');
    $('.card-radio-text').on('click', function (e) {
        e.preventDefault();
        var $_this = jQuery(this);
        $_this.parent().parent().find('.card-footer').find('.edit-task').click();
    });

    $('.card-radio').off('click');
    $('.card-radio').on('click', function (e) {
        e.preventDefault();
        var $_this = jQuery(this);
        Swal.fire({
            title: 'Are you sure?',
            showCancelButton: true,
            confirmButtonColor: "#0b5ed7",
            confirmButtonText: 'Complete',
            showLoaderOnConfirm: true,
        }).then((result) => {
            if (result.isConfirmed) {
                var tempObj = { '_id': $_this.data('comment-id'), 'meta_instanceID': $_this.data('instance-id'), 'task_status': taskStatuses?.find(rows => rows?.label == 'Complete')?.value, };
                jQuery('.loader-container').removeClass('d-none');
                postRequest(`tasks/update/`, tempObj).then(res1 => {
                    $('.datepicker').hide();
                    loadTasksFromApi($_this.data('instance-id'), true, true, 'Your task from DE Data Portal has been updated.', false);
                });
            }
        });
    });

    $('[data-toggle="popover"]').on('shown.bs.popover', function () {
        $(this).addClass('text-primary')
        $(this).addClass('border-primary')
    });

    $('[data-toggle="popover"]').on('hidden.bs.popover', function () {
        $(this).removeClass('text-primary')
        $(this).removeClass('border-primary')
    });

    tableSettingsParams.data = tableSettingsParams.data;
    setTimeout(() => {
        handsontableInitialization();
        setTimeout(() => {
            if (details['totalComments']) {
                $('[data-comments-submission-id="' + details['meta.instanceID'] + '"]').find('a').html(details['totalComments']);
            } else {
                $('[data-comments-submission-id="' + details['meta.instanceID'] + '"]').find('a').html('Add');
            }
        }, 10);
    }, 10);

    const tempUrl = new URL(location);
    var currentSubmissionId = tempUrl.searchParams.get("submissionId") ?? 0;
    taskId = tempUrl.searchParams.get("task") ?? taskId;
    if (tempUrl.searchParams.get("task")) {
        let url = new URL(location.href);
        url.searchParams.delete('task');
        url.searchParams.delete('taskId');
        url.searchParams.delete('submissionId');
        window.history.pushState('', '', url);
    }
    if (reload) {
        if (currentSubmissionId != 0 && taskId != 0) {
            $('.edit-task').find('[data-comment-id="' + taskId + '"]').click();
            jQuery('.tasks-container').addClass('d-none')
            jQuery('.tasks-add-container').addClass('d-none')
            jQuery('.tasks-add-new-user-container').addClass('d-none')
            jQuery('.add-link-task').addClass('d-none')
            jQuery('#exampleAddCommentModal').find('.modal-body').removeClass('d-none');
            jQuery('.back-link-task').removeClass('d-none')
            setTimeout(() => {
                jQuery('.loader-container').addClass('d-none');
            }, 1000);
        } else if (taskId) {
            jQuery('.tasks-container').addClass('d-none')
            jQuery('.tasks-add-container').addClass('d-none')
            jQuery('.tasks-add-new-user-container').addClass('d-none')
            jQuery('.add-link-task').addClass('d-none')
            jQuery('#exampleAddCommentModal').find('.modal-body').removeClass('d-none');
            jQuery('.back-link-task').removeClass('d-none')
            setTimeout(() => {
                if ($('.edit-task').find('[data-comment-id="' + taskId + '"]').length) {
                    $('.edit-task').find('[data-comment-id="' + taskId + '"]').click();
                } else if ($('.view-completed-task').find('[data-comment-id="' + taskId + '"]').length) {
                    $('.view-completed-task').find('[data-comment-id="' + taskId + '"]').click();
                }
                setTimeout(() => {
                    jQuery('.loader-container').addClass('d-none');
                }, 1000);
            }, 100);
        } else {
            jQuery('.tasks-container').removeClass('d-none')
            jQuery('.tasks-add-container').addClass('d-none')
            jQuery('.tasks-add-new-user-container').addClass('d-none')
            jQuery('.add-link-task').removeClass('d-none')
            jQuery('#exampleAddCommentModal').find('.modal-body').removeClass('d-none');
            jQuery('.back-link-task').addClass('d-none')
            setTimeout(() => {
                jQuery('.loader-container').addClass('d-none');
            }, 1000);
        }
    } else {
        setTimeout(() => {
            jQuery('.loader-container').addClass('d-none');
        }, 1000);
    }
}

function prepareHtmlForEdit(dataForEdit) {
    var currentNotificationRecipient = dataForEdit.data('notification-recipient')?.split(',');
    var current_data = dataForEdit.data();
    var notificationUsers = current_data.notificationRecipient.split(',');
    var notificationUsersIds = [];
    notificationUsers?.map(rows => {
        notificationUsersIds.push(notificationRecipients?.find(row => row.label == rows)?.value)
    })
    var htmlForEdit = '';
    htmlForEdit += '<table class="table table-borderless comments-table-container">';
    htmlForEdit += '<thead>';
    htmlForEdit += '<tr>';
    htmlForEdit += '<th><label>Task Recipient</label></th>';
    htmlForEdit += '<td class="notification_recipient_container">';
    htmlForEdit += '<select name="update_notification_recipient" id="update_notification_recipient" class="update_row_notification_recipient notification_recipient_comment_update form-control w-100 select2-init" multiple="true">';
    notificationRecipients.map(({ value, label }, index) => {
        htmlForEdit += '<option value="' + value + '" ' + (notificationUsersIds.indexOf(value) >= 0 ? 'selected="selected"' : '') + '  key="notification-recipient-comment-' + index + '" >' + label + '</option>';
    });
    htmlForEdit += '</select>';
    htmlForEdit += '</td>';
    htmlForEdit += '</tr>';
    htmlForEdit += '</thead>';
    htmlForEdit += '</table>';

    return htmlForEdit;
}

function prepareHtmlForTaskByStatus(element) {
    let dueDate = new Date(element?.due_date);
    let month = '';
    let dateHtml = '';
    let editHtml = '';
    let btnClass = 'btn-default btn-sm';
    if (dueDate != 'Invalid Date') {
        month = dueDate.getMonth() < 12 ? dueDate.getMonth() + 1 : 1;
        let dueDateData = month + '/' + dueDate.getDate();
        const date2 = new Date();
        if (dueDate < date2) {
            btnClass = 'btn-danger-custom btn-sm';
        }
        if (checkIfWriteAccess() && element.task_status != 'Complete') {
            // btnClass += ' datepicker';
        }
        dateHtml = '<i class="mr-1 fa fa-calendar edit-task-calendar" data-comment-id="' + element.id + '"></i><button type="button" class="edit-task-calendar gap-1 btn px-3 ' + btnClass + ' d-flex align-items-center" data-comment-id="' + element.id + '" data-instance-id="' + element['meta.instanceID'] + '" data-value="' + element.task + '" data-task-status="' + element['task_status'] + '" data-task-priority="' + element['task_priority'] + '" data-notification-recipient="' + element['notification_recipients']?.full_name + '">' + dueDateData + '</button>';
    } else {
        if (checkIfWriteAccess() && element.task_status != 'Complete') {
            // btnClass += ' datepicker';
        }
        dateHtml = '<i class="fa fa-calendar edit-task-calendar" data-comment-id="' + element.id + '"></i><button type="button" class="edit-task-calendar gap-1 btn px-3 ' + btnClass + ' d-flex align-items-center" data-comment-id="' + element.id + '" data-instance-id="' + element['meta.instanceID'] + '" data-value="' + element.task + '" data-task-status="' + element['task_status'] + '" data-task-priority="' + element['task_priority'] + '" data-notification-recipient="' + element['notification_recipients']?.full_name + '">Due</button>';
    }
    if (checkIfWriteAccess()) {
        editHtml = '<button type="button" class="gap-1 btn border-0 px-2 mr-3" data-comment-id="' + element.id + '" data-due-date="' + element.due_date + '" data-instance-id="' + element['meta.instanceID'] + '" data-value="' + element.task + '" data-task-status="' + element['task_status'] + '" data-task-priority="' + element['task_priority'] + '" data-notification-recipient="' + element['notification_recipients']?.full_name + '"><i class="fa fa-pencil text-primary"></i></button>';
    }
    let userHtml = '';
    if (checkIfWriteAccess() && element.task_status != 'Complete') {
        // userHtml += '<div class="circle-avatar-plain cursor-pointer mt-1"  data-comment-id="' + element.id + '" data-instance-id="' + element['meta.instanceID'] + '" data-value="' + element.task + '" data-task-status="' + element['task_status'] + '" data-task-priority="' + element['task_priority'] + '" data-notification-recipient="' + element['notification_recipients']?.full_name + '"><i class="fa fa-user-plus fa-2x"></i></div>';
    }
    let reciepients = element?.notification_recipients?.initials;
    if (reciepients) {
        var names = '';
        for (let recipientIndex = 0; recipientIndex < reciepients.length; recipientIndex++) {
            const name = element?.notification_recipients?.full_name[recipientIndex];
            const initial = reciepients[recipientIndex];
            if (recipientIndex < 3) {
                if (name?.trim() != '') {
                    var userClass = notificationRecipients.find(rowUser => rowUser?.label == name)?.backgroundColor;
                    userHtml += '<div class="circle-avatar cursor-pointer ' + userClass + ' " title="' + name + '" data-tooltip="' + name + '">' + initial + '</div>';
                }
            }
            names += name + ', ';
        }
        if (reciepients.length > 3) {
            names = names.slice(0, names.length - 2);
            userHtml += '<div class="mt-1 cursor-pointer" title="' + names + '" data-tooltip="' + names + '">...</div>';
        }
    }
    let commentStrTemp = '';
    if (element.task_status == 'In Progress' || element.task_status == 'Pending' || element.task_status == 'Question') {
        let tempTemplate = $('#templateForComment').clone();
        $('.tasks-add-container').find('input.comment_id').val(element.id);
        $('.tasks-add-container').find('input.comment_id').attr('data-comment-id', element.id);
        tempTemplate.find('p.card-text').html(element.task);
        tempTemplate.find('p.card-text').attr('for', 'radio-' + element.id);
        tempTemplate.find('.card-radio').attr('id', 'radio-' + element.id);
        // tempTemplate.find('.card-radio-text').attr('htmlFor', 'radio-' + element.id);
        tempTemplate.find('.card-radio').attr('data-id', element.id);
        tempTemplate.find('.card-radio-text').attr('data-id', element.id);
        tempTemplate.find('.card-radio').attr('data-comment-id', element.id);
        tempTemplate.find('.card-radio-text').attr('data-comment-id', element.id);
        tempTemplate.find('.card-radio').attr('data-instance-id', element['meta.instanceID']);
        tempTemplate.find('.card-radio-text').attr('data-instance-id', element['meta.instanceID']);
        tempTemplate.find('.user').html(userHtml);
        tempTemplate.find('.date').html(dateHtml);
        tempTemplate.find('.edit-task').html(editHtml);
        $('.in-progress-data').append(tempTemplate[0]?.firstChild);
    }

    if (element.task_status == 'Complete') {
        // $('.tasks-add-container').find('input.comment_id').val(element.id);
        // $('.tasks-add-container').find('input.comment_id').attr('data-comment-id', element.id);
        // commentStrTemp += '<p class="view-completed-task"><span class="gap-1 px-3 cursor-pointer" data-comment-id="' + element.id + '" data-due-date="' + element.due_date + '" data-instance-id="' + element['meta.instanceID'] + '" data-value="' + element.task + '" data-task-status="' + element['task_status'] + '" data-task-priority="' + element['task_priority'] + '" data-notification-recipient="' + element['notification_recipients']?.full_name + '">' + element.task + '</span></p>';
        // completedArr += commentStrTemp;

        editHtml = '<button type="button" class="gap-1 btn border-0 px-2 mr-3" data-comment-id="' + element.id + '" data-due-date="' + element.due_date + '" data-instance-id="' + element['meta.instanceID'] + '" data-value="' + element.task + '" data-task-status="' + element['task_status'] + '" data-task-priority="' + element['task_priority'] + '" data-notification-recipient="' + element['notification_recipients']?.full_name + '"><i class="fa fa-pencil text-primary"></i></button>';
        let tempTemplate = $('#templateForComment').clone();
        $('.tasks-add-container').find('input.comment_id').val(element.id);
        $('.tasks-add-container').find('input.comment_id').attr('data-comment-id', element.id);
        tempTemplate.find('p.card-text').html(element.task);
        tempTemplate.find('p.card-text').attr('for', 'radio-' + element.id);
        tempTemplate.find('.card-radio').attr('id', 'radio-' + element.id);
        // tempTemplate.find('.card-radio-text').attr('htmlFor', 'radio-' + element.id);
        tempTemplate.find('.card-radio').attr('data-id', element.id);
        tempTemplate.find('.card-radio').attr('checked', 'checked');
        tempTemplate.find('.card-radio-text').attr('data-id', element.id);
        tempTemplate.find('.card-radio').attr('data-comment-id', element.id);
        tempTemplate.find('.card-radio-text').attr('data-comment-id', element.id);
        tempTemplate.find('.card-radio').attr('data-instance-id', element['meta.instanceID']);
        tempTemplate.find('.card-radio-text').attr('data-instance-id', element['meta.instanceID']);
        tempTemplate.find('.user').html(userHtml);
        tempTemplate.find('.date').html('');
        tempTemplate.find('.edit-task').removeClass('edit-task').addClass('view-completed-task');
        tempTemplate.find('.view-completed-task').html(editHtml);
        $('.completed-data').append(tempTemplate[0]?.firstChild);

    }

    if (element.task_status == 'Information Only') {
        let tempTemplate = $('#templateForComment').clone();
        $('.tasks-add-container').find('input.comment_id').val(element.id);
        $('.tasks-add-container').find('input.comment_id').attr('data-comment-id', element.id);
        tempTemplate.find('p.card-text').html(element.task);
        tempTemplate.find('p.card-text').attr('for', 'radio-' + element.id);
        tempTemplate.find('.card-radio').attr('id', 'radio-' + element.id);
        // tempTemplate.find('.card-radio-text').attr('htmlFor', 'radio-' + element.id);
        tempTemplate.find('.card-radio').attr('data-id', element.id);
        tempTemplate.find('.card-radio-text').attr('data-id', element.id);
        tempTemplate.find('.card-radio').attr('data-comment-id', element.id);
        tempTemplate.find('.card-radio-text').attr('data-comment-id', element.id);
        tempTemplate.find('.card-radio').attr('data-instance-id', element['meta.instanceID']);
        tempTemplate.find('.card-radio-text').attr('data-instance-id', element['meta.instanceID']);
        tempTemplate.find('.user').html(userHtml);
        tempTemplate.find('.date').html(dateHtml);
        tempTemplate.find('.edit-task').html(editHtml);
        $('.information-data').append(tempTemplate[0]?.firstChild);
    }
}

function compulsoryColumns(columns, columnsInitialParam) {
    var needed_columns = [];
    columns = columns.length > 0 && columns[0] !== '' ? columns : columnsInitialParam;
    if (columns) {
        for (var i in columns) {
            if (columns.hasOwnProperty(i)) {
                if (needed_columns.indexOf(columns[i]) < 0) {
                    needed_columns.push(columns[i]);
                }
            }
        }
    }
    return needed_columns;
}

function arrange_column_header(columnData) {
    var ANS = [];
    columnData = Object.assign({}, columnData);
    for (var i in columnData) {
        if (columnData.hasOwnProperty(i)) {
            var one = {};
            if (columnData[i] == "tasks") {
                one['renderer'] = commentsRenderer;
                one['readOnly'] = true;
            }
            else if (columnData[i] == "comments_count") {
                one['renderer'] = commentsRenderer;
                one['readOnly'] = true;
            }
            else if (columnData[i] == "client_ids") {
                one['type'] = "dropdown";
                one['source'] = clients?.map(rows => {
                    return rows?.label;
                });
            }
            else if (columnData[i] == "projects") {
                // one['type'] = "dropdown";
                one['type'] = "autocomplete";
                one['strict'] = true;
                one['source'] = projects?.map(rows => {
                    return rows?.label;
                });
            }
            else if (columnData[i] == "project") {
                // one['type'] = "dropdown";
                one['type'] = "autocomplete";
                one['strict'] = true;
                one['source'] = projects?.map(rows => {
                    return rows?.label;
                });
            }
            else if (columnData[i] == "monitor_id" || columnData[i] == "Monitor") {
                var monitorTemp = ['Unknown'];
                for (var indTemp in editableDataOptions.monitors) {
                    if (editableDataOptions.monitors.hasOwnProperty(indTemp)) {
                        monitorTemp.push(editableDataOptions.monitors[indTemp]);
                    }
                }
                one['type'] = "autocomplete";
                one['strict'] = true;
                // one['filter'] = false;
                // one['type'] = "dropdown";
                one['source'] = monitorTemp;
            }
            else if (columnData[i] == "QcFlag" || columnData[i] == "QCFlag" || columnData[i] == "qc_flag") {
                one['type'] = "dropdown";
                one['source'] = qcFlags?.map(rows => {
                    return rows?.label;
                });
            }
            else if (columnData[i] == "site") {
                var sitesTemp = ['Unknown'];
                for (var indSite in editableDataOptions.sites) {
                    if (editableDataOptions.sites.hasOwnProperty(indSite)) {
                        sitesTemp.push(editableDataOptions.sites[indSite]);
                    }
                }
                // one['type'] = "dropdown";
                one['type'] = "autocomplete";
                one['strict'] = true;
                // one['filter'] = false;
                one['source'] = sitesTemp;
            } else if (columnData[i] == "meta.instanceID") {
                one['width'] = 370; // Set the desired width for the column
                one['className'] = 'htNoWrap'; // Apply no-wrap style          
            } else if (columnData[i] == "monitor") {
                var monitorTemp = ['Unknown'];
                for (var indMonitor1 in editableDataOptions.monitors) {
                    if (editableDataOptions.monitors.hasOwnProperty(indMonitor1)) {
                        monitorTemp.push(editableDataOptions.monitors[indMonitor1]);
                    }
                }
                // one['type'] = "dropdown";
                one['type'] = "autocomplete";
                one['strict'] = true;
                // one['filter'] = false;
                one['source'] = monitorTemp;
                one['readOnly'] = false;
            }

            else {
                one['type'] = "text";
                one['readOnly'] = false;
            }

            if (imageFields.indexOf(columnData[i]) >= 0) {
                one['type'] = "text"
                one['renderer'] = "html";
                one['readOnly'] = true;
                one['className'] = 'hide_filters';
            }

            if (repeatColumns.indexOf(columnData[i]) >= 0) {
                one['type'] = "text"
                one['renderer'] = "html";
                one['readOnly'] = true;
                one['className'] = 'hide_filters';
            }

            if (columnData[i] != 'monitor' && (uneditable.indexOf(columnData[i]) >= 0 || uneditable.indexOf(columnData[i]?.toLowerCase()) >= 0)) {
                one['readOnly'] = true;
            }
            one['columnIndex'] = i;
            one['columnName'] = columnData[i];
            ANS.push(one);
        }
    }
    return ANS;
}

const getCommentRelatedOptions = async () => {
    await postRequest('tasks/get-statuses/').then(response => {
        let resData = response.data?.data;
        if (resData.task_statuses) {
            let propertyNames = Object.keys(resData.task_statuses);
            let resDataArray = [];
            let resDataArrayTemp = [];
            let completeStatus = {};
            let completeStatusRow = {};
            propertyNames.map(rows => {
                if (resData.task_statuses[rows] != 'Complete') {
                    resDataArray.push({ label: resData.task_statuses[rows], value: rows })
                    resDataArrayTemp.push(rows)
                } else {
                    completeStatus = { label: resData.task_statuses[rows], value: rows };
                    completeStatusRow = rows;
                }
            })
            resDataArray.push(completeStatus)
            resDataArrayTemp.push(completeStatusRow)
            // resDataArray = sortObject(resDataArray);
            taskStatuses = resDataArray;
        }
    }).catch(error => {
        clearCache(error);
    })

    await postRequest('tasks/get-notification-recipients/').then(response => {
        let resData = response.data?.data;
        let colors = [
            'blue',
            'lightblue',
            'lightgreen',
            'orange',
            'lightorange',
            'aquamarine',
            'lightgray',
            'gray',
        ];
        if (resData.recipients) {
            let propertyNames = Object.keys(resData.recipients);
            let resDataArray = [];
            let resDataArrayTemp = [];
            propertyNames.map((rows, indexUser) => {
                resDataArray.push({ label: resData.recipients[rows], value: rows, backgroundColor: colors[indexUser] ? colors[indexUser] : 'blue' })
                resDataArrayTemp.push(rows)
            })
            resDataArray = sortObject(resDataArray);
            notificationRecipients = resDataArray;
        }

    }).catch(error => {
        clearCache(error);
    })

    await postRequest('tasks/get-priorities/').then(response => {
        let resData = response.data?.data;
        if (resData.task_priorities) {
            let propertyNames = Object.keys(resData.task_priorities);
            let resDataArray = [];
            let resDataArrayTemp = [];
            propertyNames.map(rows => {
                resDataArray.push({ label: resData.task_priorities[rows], value: rows })
                resDataArrayTemp.push(rows)
            })
            taskPriorities = resDataArray;
        }
    }).catch(error => {
        clearCache(error);
    })
}

const getEditableDataOptions = async () => {
    await postRequest('data/get-editable-data-options/').then(response => {
        editableDataOptions = response.data?.data;
    }).catch(error => {
        clearCache(error);
    })
}

function generateFilterDataObject(rowsPerPageArgument, currentPageArgument, columnData, getFromCookie = false, isMapData = false) {
    let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
    let returnVariablesData = returnVariables();
    var returnObj = {
        startDate: $('[name="start_date"]').val(),
        endDate: $('[name="end_date"]').val(),
        'meta.instanceID': $('[name="submission_id"]').val() ? [$('[name="submission_id"]').val()] : '',
        'tz': getCurrentTimeZone(),
    };

    if (rowsPerPageArgument > 0) {
        returnObj['chunk'] = rowsPerPageArgument;
    }
    if (currentPageArgument > 0) {
        returnObj['start'] = currentPageArgument > 1 ? ((rowsPerPageArgument * (currentPageArgument - 1))) : currentPageArgument - 1;
    }

    if (tempStorageData?.qcFlags?.length && getFromCookie) {
        returnObj['qcFlags'] = tempStorageData?.qcFlags;
    }
    else if (returnVariablesData.old_qc_flags_length != getSelectedLength('[name="filter_qc_flag"]') && $('[name="filter_qc_flag"]').val()?.length && !getFromCookie) {
        returnObj['qcFlags'] = $('[name="filter_qc_flag"]').val();
    }
    else if (getOptionsLength('[name="filter_qc_flag"]') != getSelectedLength('[name="filter_qc_flag"]') && $('[name="filter_qc_flag"]').val()?.length) {
        returnObj['qcFlags'] = $('[name="filter_qc_flag"]').val();
    }

    if (tempStorageData?.projects?.length && getFromCookie) {
        returnObj['project'] = tempStorageData?.projects;
    }
    else if (returnVariablesData.old_projects_length != getSelectedLength('[name="filter_project"]') && $('[name="filter_project"]').val()?.length && !getFromCookie) {
        returnObj['project'] = $('[name="filter_project"]').val();
    }

    if (tempStorageData?.image_fields?.length && getFromCookie) {
        returnObj['image_fields'] = tempStorageData?.image_fields;
    }
    else if (returnVariablesData.old_image_fields_length != getSelectedLength('[name="filter_image_fields"]') && $('[name="filter_image_fields"]').val()?.length && !getFromCookie) {
        returnObj['image_fields'] = $('[name="filter_image_fields"]').val();
    }

    if (tempStorageData?.formGroups?.length && getFromCookie) {
        returnObj['formGroup'] = tempStorageData?.formGroups;
    }
    else if (returnVariablesData.old_formGroups_length != getSelectedLength('[name="filter_form_groups"]') && $('[name="filter_form_groups"]').val()?.length && !getFromCookie) {
        returnObj['formGroup'] = $('[name="filter_form_groups"]').val();
    }

    if (tempStorageData?.forms?.length && getFromCookie) {
        returnObj['forms'] = tempStorageData?.forms;
    }
    else if (returnVariablesData.old_forms_length != getSelectedLength('[name="filter_form"]') && $('[name="filter_form"]').val()?.length && !getFromCookie) {
        returnObj['forms'] = $('[name="filter_form"]').val();
    }

    if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && getFromCookie && !isMapData && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
        returnObj['sites'] = [tempStorageData?.singleSite];
    }
    else if (tempStorageData?.sites?.length && getFromCookie) {
        returnObj['sites'] = tempStorageData?.sites;
    }
    else if (returnVariablesData.old_sites_length != getSelectedLength('[name="filter_site"]') && $('[name="filter_site"]').val()?.length && !getFromCookie) {
        returnObj['sites'] = $('[name="filter_site"]').val();
    }

    if (tempStorageData?.clients?.length && getFromCookie) {
        returnObj['clients'] = tempStorageData?.clients;
    }
    else if (returnVariablesData.old_clients_length != getSelectedLength('[name="filter_client"]') && $('[name="filter_client"]').val()?.length && !getFromCookie) {
        returnObj['clients'] = $('[name="filter_client"]').val();
    }

    if (tempStorageData?.monitors?.length && getFromCookie) {
        returnObj['monitors'] = tempStorageData?.monitors;
    }
    else if (returnVariablesData.old_monitors_length != getSelectedLength('[name="filter_monitor"]') && $('[name="filter_monitor"]').val()?.length && !getFromCookie) {
        returnObj['monitors'] = $('[name="filter_monitor"]').val();
    }

    if (getOptionsLength('[name="filter_param"]') != getSelectedLength('[name="filter_param"]') && $('[name="filter_param"]').val()?.length) {
        returnObj['params'] = $('[name="filter_param"]').val();
    }

    if (tempStorageData?.commenter?.length && getFromCookie) {
        returnObj['commenter'] = tempStorageData?.commenter;
    }
    else if (returnVariablesData.old_commenter_length != getSelectedLength('[name="filter_commenter"]') && $('[name="filter_commenter"]').val()?.length && !getFromCookie) {
        returnObj['commenter'] = $('[name="filter_commenter"]').val();
    }

    if (tempStorageData?.editor?.length && getFromCookie) {
        returnObj['editor'] = tempStorageData?.editor;
    }
    else if (returnVariablesData.old_editor_length != getSelectedLength('[name="filter_editor"]') && $('[name="filter_editor"]').val()?.length && !getFromCookie) {
        returnObj['editor'] = $('[name="filter_editor"]').val();
    }

    let propertyNames = (Array.isArray(columnsInitial)) ? columnsInitial : Object.keys(columnsInitial);
    let length = 0;
    propertyNames.map((rows, index) => {
        if (Array.isArray(columnsInitial)) {
            if (columnsInitial[index] !== '_id' && columnsInitial[index] !== '__id' && columnsInitial[index] !== 'meta_task_id' && columnsInitial[index] !== 'meta_subtask_id') {
                length++;
            }
        } else {
            if (columnsInitial[rows] !== '_id' && columnsInitial[rows] !== '__id' && columnsInitial[rows] !== 'meta_task_id' && columnsInitial[rows] !== 'meta_subtask_id') {
                length++;
            }
        }
    })
    if (columnData.length && getSelectedLength('[name="filter_column"]') >= 1 && length != getSelectedLength('[name="filter_column"]')) {
        returnObj['columns'] = columnData;
    }
    return returnObj;
}

function generateFilterObject(getFromCookie = false) {
    let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
    let returnVariablesData = returnVariables();
    var returnObj = {
    }

    if (tempStorageData?.projects?.length && getFromCookie) {
        returnObj['project'] = tempStorageData?.projects;
    }
    else if (getOptionsLength('[name="filter_project"]') != getSelectedLength('[name="filter_project"]') && $('[name="filter_project"]').val()?.length && !getFromCookie) {
        returnObj['project'] = $('[name="filter_project"]').val();
    }

    if (tempStorageData?.image_fields?.length && getFromCookie) {
        returnObj['image_fields'] = tempStorageData?.image_fields;
    }
    else if (getOptionsLength('[name="filter_image_fields"]') != getSelectedLength('[name="filter_image_fields"]') && $('[name="filter_image_fields"]').val()?.length && !getFromCookie) {
        returnObj['image_fields'] = $('[name="filter_image_fields"]').val();
    }

    if (tempStorageData?.formGroups?.length && getFromCookie) {
        returnObj['formGroup'] = tempStorageData?.formGroups;
    }
    else if (getOptionsLength('[name="filter_form_groups"]') != getSelectedLength('[name="filter_form_groups"]') && $('[name="filter_form_groups"]').val()?.length && !getFromCookie) {
        returnObj['formGroup'] = $('[name="filter_form_groups"]').val();
    }

    if (tempStorageData?.forms?.length && getFromCookie) {
        returnObj['forms'] = tempStorageData?.forms;
    }
    else if (getOptionsLength('[name="filter_form"]') != getSelectedLength('[name="filter_form"]') && $('[name="filter_form"]').val()?.length && !getFromCookie) {
        returnObj['forms'] = $('[name="filter_form"]').val();
    }

    // if (tempStorageData?.sites?.length && getFromCookie) {
    //     returnObj['sites'] = tempStorageData?.sites;
    // }
    // else if (getOptionsLength('[name="filter_site"]') != getSelectedLength('[name="filter_site"]') && $('[name="filter_site"]').val()?.length && !getFromCookie) {
    //     returnObj['sites'] = $('[name="filter_site"]').val();
    // }


    if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && getFromCookie && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
        returnObj['sites'] = [tempStorageData?.singleSite];
    }
    else if (tempStorageData?.sites?.length && getFromCookie) {
        returnObj['sites'] = tempStorageData?.sites;
    }
    else if (getOptionsLength('[name="filter_site"]') != getSelectedLength('[name="filter_site"]') && $('[name="filter_site"]').val()?.length && !getFromCookie) {
        returnObj['sites'] = $('[name="filter_site"]').val();
    }

    if (tempStorageData?.clients?.length && getFromCookie) {
        returnObj['clients'] = tempStorageData?.clients;
    }
    else if (getOptionsLength('[name="filter_client"]') != getSelectedLength('[name="filter_client"]') && $('[name="filter_client"]').val()?.length && !getFromCookie) {
        returnObj['clients'] = $('[name="filter_client"]').val();
    }

    if (tempStorageData?.monitors?.length && getFromCookie) {
        returnObj['monitors'] = tempStorageData?.monitors;
    }
    else if (getOptionsLength('[name="filter_monitor"]') != getSelectedLength('[name="filter_monitor"]') && $('[name="filter_monitor"]').val()?.length && !getFromCookie) {
        returnObj['monitors'] = $('[name="filter_monitor"]').val();
    }

    if (getOptionsLength('[name="filter_param"]') != getSelectedLength('[name="filter_param"]') && $('[name="filter_param"]').val()?.length) {
        returnObj['params'] = $('[name="filter_param"]').val();
    }

    if (tempStorageData?.commenter?.length && getFromCookie) {
        returnObj['commenter'] = tempStorageData?.commenter;
    }
    else if (getOptionsLength('[name="filter_commenter"]') != getSelectedLength('[name="filter_commenter"]') && $('[name="filter_commenter"]').val()?.length && !getFromCookie) {
        returnObj['commenter'] = $('[name="filter_commenter"]').val();
    }
    if (tempStorageData?.editor?.length && getFromCookie) {
        returnObj['editor'] = tempStorageData?.editor;
    }
    else if (getOptionsLength('[name="filter_editor"]') != getSelectedLength('[name="filter_editor"]') && $('[name="filter_editor"]').val()?.length && !getFromCookie) {
        returnObj['editor'] = $('[name="filter_editor"]').val();
    }

    if (tempStorageData?.qcFlags?.length && getFromCookie) {
        returnObj['qcFlags'] = tempStorageData?.qcFlags;
    }
    else if (returnVariablesData.old_qc_flags_length != getSelectedLength('[name="filter_qc_flag"]') && $('[name="filter_qc_flag"]').val()?.length && !getFromCookie) {
        returnObj['qcFlags'] = $('[name="filter_qc_flag"]').val();
    }
    else if (getOptionsLength('[name="filter_qc_flag"]') != getSelectedLength('[name="filter_qc_flag"]') && $('[name="filter_qc_flag"]').val()?.length) {
        returnObj['qcFlags'] = $('[name="filter_qc_flag"]').val();
    }

    return returnObj;
}

const url = new URL(location);

export const Data = () => {
    const [busy, setBusy] = React.useState([]);
    const [hasFilter, setHasFilter] = React.useState([]);
    const [filterCount, setFilterCount] = React.useState(0);
    const [busyTabs, setBusyTabs] = React.useState(false);
    const [tempStorageDataState, setTempStorageDataState] = React.useState(getLocalStorage('filter_data_main_table') ?? {});
    const [isCommentsDataLoaded, setIsCommentsDataLoaded] = React.useState(false);
    const [isInitialCommentsDataLoaded, setIsInitialCommentsDataLoaded] = React.useState(false);
    const [isLogsDataLoaded, setIsLogsDataLoaded] = React.useState(false);
    const [isInitialLogsDataLoaded, setIsInitialLogsDataLoaded] = React.useState(false);
    const [isMediaDataLoaded, setIsMediaDataLoaded] = React.useState(false);
    const [isInitialMediaDataLoaded, setIsInitialMediaDataLoaded] = React.useState(false);
    const [isGraphDataLoaded, setIsGraphDataLoaded] = React.useState(false);
    const [isInitialGraphDataLoaded, setIsInitialGraphDataLoaded] = React.useState(false);
    const [isTableDataLoaded, setIsTableDataLoaded] = React.useState(true);
    const [isInitialTableDataLoaded, setIsInitialTableDataLoaded] = React.useState(true);
    const [isTableMapDataLoaded, setIsTableMapDataLoaded] = React.useState(true);
    const [initialData, setInitialData] = React.useState([]);
    const [valueDataState, setValueDataState] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [page, setPage] = React.useState(Math.ceil(initialData.length / rowsPerPage));
    const [totalRows, setTotalRows] = React.useState(Math.ceil(initialData.length));
    const [subTotalRows, setSubTotalRows] = React.useState(Math.ceil(initialData.length));
    const [currentPage, setCurrentPage] = React.useState(1);
    const [params, setParams] = React.useState([]);
    const [filterParams, setFilterParams] = React.useState([]);
    const [filterClient, setFilterClient] = React.useState([]);
    const [columns, setcolumns] = React.useState([]);
    const [filterColumns, setfilterColumns] = React.useState([]);
    const [forms, setForms] = React.useState([]);
    const [filterFormGroups, setFilterFormGroups] = React.useState([]);
    const [filterForm, setFilterForm] = React.useState([]);
    const [filterSite, setFilterSite] = React.useState();
    const [filterMonitor, setFilterMonitor] = React.useState([]);
    const [minStartDate, setMinStartDate] = React.useState('');
    const [maxStartDate, setMaxStartDate] = React.useState('');
    const [minEndDate, setMinEndDate] = React.useState('');
    const [maxEndDate, setMaxEndDate] = React.useState('');
    const [filterQcFlags, setFilterQcFlags] = React.useState([]);
    const [filterProjects, setFilterProjects] = React.useState([]);
    const [showReapeatTable, setReapeatTable] = React.useState({ show: false, meta_id: null, column: null, data: null, headers: null, col_headers: null });
    const [isTableDataChanged, setIsTableDataChanged] = React.useState(false);

    const { data: rData } = useQuery(['repeatedData', showReapeatTable.meta_id, showReapeatTable.column], () => repeatedData(showReapeatTable.meta_id, showReapeatTable.column), {
        enabled: !!showReapeatTable.meta_id,
        onSuccess: (response) => {
            if (response.data.length > 0) {
                setReapeatTable((prev) => ({ ...prev, data: response.data, headers: response.headers, col_headers: response.col_headers, show: true }))
            }
        }
    })

    React.useEffect(() => {
        tableSettingsParams = {
            data: [],
            selectionMode: 'single', // 'single', 'range' or 'multiple',
            allowEmpty: true,
            fillHandle: true,
            columns: [],
            stretchH: 'all',
            // preventOverflow: 'horizontal',
            colHeaders: [],
            columnSorting: true,
            afterColumnSort() {
                setTimeout(() => {
                    initCommentsModal();
                }, 1000);
            },
            sortIndicator: true,
            filters: true,
            strict: true,
            dropdownMenu: true,
            width: '100%',
            height: '100vh',
            scrollH: 'auto',
            scrollV: 'auto',
            stretchH: 'last', //actually you don't have to declare it because it is default
            minSpareRows: 0,
            // height: 'auto',
            minWidth: '100%',
            rowHeaders: true,
            // rowHeights: 23,
            // contextMenu: true,
            contextMenu: ['undo', 'redo', 'copy', 'cut', 'make_read_only', 'alignment'],
            startRows: 5,
            startCols: 5,
            manualRowResize: true,
            manualColumnResize: true,
            manualRowMove: true,
            licenseKey: 'non-commercial-and-evaluation',
            afterChange: function (changes, source) {
                let columnData = $('[name="filter_column"]').val();
                // columnData = compulsoryColumns(columnData, columnsDataTableInitial);
                columnData = columnsDataTableInitial;
                if (source === "autofill" || source === 'edit' || source === 'paste') {
                    for (var j in changes) {
                        if (changes.hasOwnProperty(j)) {
                            var rowNumber = changes[j][0];
                            var metaId = hot.getDataAtRowProp(rowNumber, columnData.indexOf('meta.instanceID'));
                            var OdkID = hot.getDataAtRowProp(rowNumber, columnData.indexOf('odk_form_id'));
                            var metainstanceid = hot.getDataAtRowProp(rowNumber, columnData.indexOf('meta.instanceID'));
                            var oldData = changes[j][2];
                            var newData = changes[j][3];
                            var colName = columnData[changes[j][1]];
                            if (colName) {
                                if (colName == "Monitor" || colName == "MonID") {
                                    if (oldData.indexOf('Unknown') >= 0) {
                                        oldData = "-1";
                                    }
                                    else {
                                        oldData = oldData.split('_')[1];
                                    }
                                    if (newData.indexOf('Unknown') >= 0) {
                                        newData = "-1";
                                    }
                                    else {
                                        newData = newData.split('_')[1];
                                    }
                                }
                                if (colName == "qc_flag") {
                                    oldData = qcFlags.find(rows => rows.label == oldData)?.value;
                                    if (!oldData) {
                                        oldData = "";
                                    }
                                    newData = qcFlags.find(rows => rows.label == newData)?.value;
                                    if (!newData) {
                                        newData = "";
                                    }
                                }
                                var tempSitesObj = [];
                                for (var i in editableDataOptions.sites) {
                                    if (editableDataOptions.sites.hasOwnProperty(i)) {
                                        tempSitesObj.push({ label: editableDataOptions.sites[i], value: i });
                                    }
                                }
                                if (colName == "site" && tempSitesObj.length > 0) {
                                    var oldDataTemp = tempSitesObj.find(rows => rows.label == oldData)?.value
                                    if (!oldDataTemp && (oldData.indexOf('Unknown') >= 0 && oldData.indexOf('Unknown') <= 8)) {
                                        oldData = "-1";
                                    } else {
                                        oldData = tempSitesObj.find(rows => rows.label == oldData)?.value
                                        if (!oldData) {
                                            oldData = "";
                                        }
                                    }

                                    var newDataTemp = tempSitesObj.find(rows => rows.label == newData)?.value;
                                    if (!newDataTemp && (newData.indexOf('Unknown') >= 0 && newData.indexOf('Unknown') <= 8)) {
                                        newData = "-1";
                                    } else {
                                        newData = tempSitesObj.find(rows => rows.label == newData)?.value;
                                        if (!newData) {
                                            newData = "";
                                        }
                                    }
                                }
                                if (colName == "monitor" && monitors.length > 0) {
                                    var oldDataTemp = monitors.find(rows => rows.label == oldData)?.value;
                                    if (!oldDataTemp && oldData.indexOf('Unknown') >= 0) {
                                        oldData = "-1";
                                    } else {
                                        oldData = monitors.find(rows => rows.label == oldData)?.value;
                                        if (!oldData) {
                                            oldData = "";
                                        }
                                    }
                                    var newDataTemp = monitors.find(rows => rows.label == newData)?.value;
                                    if (!newDataTemp && newData.indexOf('Unknown') >= 0) {
                                        newData = "-1";
                                    } else {
                                        newData = monitors.find(rows => rows.label == newData)?.value;
                                        if (!newData) {
                                            newData = "";
                                        }
                                    }
                                }

                                if (colName == "project" && projects.length > 0) {
                                    var oldDataTemp = projects.find(rows => rows.label == oldData)?.value
                                    if (!oldDataTemp && (oldData.indexOf('Unknown') >= 0 && oldData.indexOf('Unknown') <= 8)) {
                                        oldData = "-1";
                                    } else {
                                        oldData = projects.find(rows => rows.label == oldData)?.value
                                        if (!oldData) {
                                            oldData = "";
                                        }
                                    }

                                    var newDataTemp = projects.find(rows => rows.label == newData)?.value;
                                    if (!newDataTemp && (newData.indexOf('Unknown') >= 0 && newData.indexOf('Unknown') <= 8)) {
                                        newData = "-1";
                                    } else {
                                        newData = projects.find(rows => rows.label == newData)?.value;
                                        if (!newData) {
                                            newData = "";
                                        }
                                    }
                                }

                                if (colName == "Site") {
                                    if (oldData.indexOf('Unknown') >= 0) {
                                        oldData = "-1";
                                    }
                                    else {
                                        oldData = oldData.split('_')[0];
                                    }
                                    if (newData.indexOf('Unknown') >= 0) {
                                        newData = "-1";
                                    }
                                    else {
                                        newData = newData.split('_')[0];
                                    }
                                }
                                if (oldData?.toString() != newData?.toString()) {
                                    setIsTableDataChanged(true);
                                    if (updateData.hasOwnProperty(metaId)) {
                                        if (updateData[metaId].hasOwnProperty(colName)) {
                                            updateData[metaId][colName]['new'] = newData;
                                        }
                                        else {
                                            updateData[metaId][colName] = {};
                                            updateData[metaId][colName]['old'] = oldData;
                                            updateData[metaId][colName]['new'] = newData;
                                            updateData[metaId]['meta_instanceID'] = metainstanceid;
                                        }
                                    }
                                    else {
                                        updateData[metaId] = {};
                                        updateData[metaId][colName] = {};
                                        updateData[metaId][colName]['old'] = oldData;
                                        updateData[metaId][colName]['new'] = newData;
                                        updateData[metaId]['meta_instanceID'] = metainstanceid;
                                    }
                                    if (colName == "Site" && allow_siteid == true) {
                                        var induz = newData.split('_')[0];
                                        if (induz != "-1") {
                                            if (sites_store_lat.hasOwnProperty(induz)) {
                                                allow_long = false;
                                                hot.setDataAtCell(rowNumber, (g_latitude), sites_store_lat[induz]);
                                                hot.setDataAtCell(rowNumber, (g_longitude), sites_store_long[induz]);
                                                allow_long = true;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            afterOnCellMouseDown: function (event, coords) {
                if (columnsDataTableInitial[coords.col] && repeatColumns.includes(columnsDataTableInitial[coords.col])) {
                    let column = columnsDataTableInitial[coords.col]
                    var metaId = hot.getDataAtRowProp(coords.row, columnsDataTableInitial.indexOf('meta.instanceID'));
                    setReapeatTable((prev) => ({ ...prev, 'column': column, 'meta_id': metaId }))
                }
            },
            cells: function (row, col, prop) {
                var cellProperties = {};
                if (prop === 'meta_instanceID' || prop === 'meta.instanceID') {
                    cellProperties.readOnly = true;
                }
                if (prop === '_id') {
                    cellProperties.readOnly = true;
                }

                return cellProperties;
            },
        };
    }, []);

    const setTotalRowsCustom = (value) => {
        setTotalRows(value);
    }

    const setPageCustom = (value) => {
        setPage(value)
    }

    // Change Tab
    const handleChange = (event, newValue) => {
        var oldTabValue = valueDataState;
        valueData = newValue;
        setValueDataState(newValue);
        setBusyTabs(true);
        if (newValue == 0) {
            $('.pagination-container-inner').show();
            setTimeout(() => {
                setIsTableDataLoaded(true);
                setIsInitialTableDataLoaded(true);
                setIsTableMapDataLoaded(false);
                setIsCommentsDataLoaded(false);
                setIsLogsDataLoaded(false);
                setIsMediaDataLoaded(false);
                setIsGraphDataLoaded(false);
                setCurrentPage(currentPage);
                let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
                if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
                    let tempTableStorageData = getLocalStorage('tableData') ?? [];
                    setTotalRows(tempTableStorageData?.length ?? 1)
                    setPage(1)
                }
                const k1 = tempStorageDataState;
                const k2 = getLocalStorage('filter_data_main_table') ?? {};

                if (!isInitialTableDataLoaded || JSON.stringify(k1) !== JSON.stringify(k2)) {
                    getRefreshedTableData(rowsPerPage, currentPage, false, false);
                }
                onFilterModel(true, true, '', oldTabValue, newValue).then(res => {
                    setTimeout(() => {
                        handsontableInitialization();
                    }, 100);
                });
            }, 100);
        } else if (newValue == 1) {
            let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
            if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {

            } else {
                jQuery('.loader-container').removeClass('d-none');
            }
            setTimeout(() => {
                if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1 && isTableMapDataLoaded) {

                } else {
                    loadMapData(mapData, allSites, rowsPerPage, currentPage);
                }
                setIsTableDataLoaded(false);
                setIsTableMapDataLoaded(true);
                setIsCommentsDataLoaded(false);
                setIsLogsDataLoaded(false);
                setIsMediaDataLoaded(false);
                setIsGraphDataLoaded(false);
                onFilterModel(true, true, '', oldTabValue, newValue).then(res => {

                });
            }, 100);
        } else if (newValue == 2) {
            $('.pagination-container-inner').show();
            setTimeout(() => {
                setIsTableDataLoaded(false);
                setIsTableMapDataLoaded(false);
                setIsCommentsDataLoaded(true);
                setIsInitialCommentsDataLoaded(true);
                setIsLogsDataLoaded(false);
                setIsMediaDataLoaded(false);
                setIsGraphDataLoaded(false);
                select2Initialization();
                onFilterModel(true, true, 'comments', oldTabValue, newValue).then(res => {
                    $('.reload-comments-data').click();
                });
            }, 100);
        } else if (newValue == 3) {
            $('.pagination-container-inner').show();
            setTimeout(() => {
                setIsTableDataLoaded(false);
                setIsTableMapDataLoaded(false);
                setIsCommentsDataLoaded(false);
                setIsLogsDataLoaded(true);
                setIsInitialLogsDataLoaded(true);
                setIsMediaDataLoaded(false);
                setIsGraphDataLoaded(false);
                select2Initialization();
                onFilterModel(true, true, 'log', oldTabValue, newValue).then(res => {
                    $('.reload-log-data').click();
                });
            }, 100);
        } else if (newValue == 4) {
            $('.pagination-container-inner').show();
            setTimeout(() => {
                setIsTableDataLoaded(false);
                setIsTableMapDataLoaded(false);
                setIsCommentsDataLoaded(false);
                setIsLogsDataLoaded(false);
                setIsMediaDataLoaded(true);
                setIsInitialMediaDataLoaded(true);
                setIsGraphDataLoaded(false);
                select2Initialization();
                onFilterModel(true, true, '', oldTabValue, newValue).then(res => {
                    $('.reload-media-data').click();
                    $('.reload-media-initialization').click();
                });
            }, 100);
        } else if (newValue == 5) {
            $('.pagination-container-inner').show();
            setTimeout(() => {
                setIsTableDataLoaded(false);
                setIsTableMapDataLoaded(false);
                setIsCommentsDataLoaded(false);
                setIsLogsDataLoaded(false);
                setIsMediaDataLoaded(false);
                setIsGraphDataLoaded(true);
                setIsInitialGraphDataLoaded(true);
                select2Initialization();
                onFilterModel(true, true, '', oldTabValue, newValue).then(res => {
                    $('.refresh-graph-data-main').on('click', function () {
                        $('.refresh-graph-data').click();
                    });
                    $('.refresh-graph-data').click();
                    $('.reload-graph-initialization').click();
                    initRefreshGraphMediaAction('.refresh-media-graph-data-main', 5);
                });
            }, 100);
        }
        const url = new URL(location);
        url.searchParams.set("tab", newValue);
        history.pushState({}, "", url);
        setTimeout(() => {
            setBusyTabs(false);
        }, 1000);
    };

    // Filter form, project, form group, site, client, monitor selection
    const handleSelectionChange = (event, newValue, resDataArgument, element_name) => {
        var filter_flag = getFilterFlag(element_name);
        if (filter_flag == 1 && element_name !== 'filter_image_fields' && element_name !== 'filter_qc_flag') {
            onFilterModel(false, true, '').then(res => {
                reloadTheData(projectFilters, false, true);
            });
        }
    }

    const reloadTheData = (resDataArgument, onlySelection, resetColumns) => {
        const resData = resDataArgument ? resDataArgument : projectFilters;
        let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};

        if (resData.projects) {
            let propertyNames = Object.keys(resData.projects);
            let resDataArray = [];
            let resDataArrayTemp = [];
            propertyNames.map(rows => {
                resDataArray.push({ label: resData.projects[rows], value: rows })
                resDataArrayTemp.push(rows)
            })
            if (!onlySelection) {
                resDataArray = sortObject(resDataArray);
                projects = resDataArray;
            }
            setFilterProjects(resDataArrayTemp);
        }
        if (resData?.assigner) {
            let propertyNames = Object.keys(resData?.assigner);
            let resDataArray = [];
            commenter = [];
            let resDataArrayTemp = [];
            propertyNames.map(rows => {
                commenter.push({ label: resData?.assigner[rows], value: rows })
                resDataArrayTemp.push(rows)
            })
            commenter = sortObject(commenter);
        }
        if (resData?.task_priorities) {
            let propertyNames = Object.keys(resData?.task_priorities);
            let resDataArray = [];
            task_priorities_select = [];
            let resDataArrayTemp = [];
            propertyNames.map(rows => {
                task_priorities_select.push({ label: resData?.task_priorities[rows], value: rows })
                resDataArrayTemp.push(rows)
            })
            task_priorities_select = sortObject(task_priorities_select);
        }
        if (resData?.task_recipients) {
            let propertyNames = Object.keys(resData?.task_recipients);
            let resDataArray = [];
            task_recipients_select = [];
            let resDataArrayTemp = [];
            propertyNames.map(rows => {
                task_recipients_select.push({ label: resData?.task_recipients[rows], value: rows })
                resDataArrayTemp.push(rows)
            })
            task_recipients_select = sortObject(task_recipients_select);
        }
        if (resData?.editors) {
            let propertyNames = Object.keys(resData?.editors);
            let resDataArray = [];
            editors = [];
            let resDataArrayTemp = [];
            propertyNames.map(rows => {
                editors.push({ label: resData?.editors[rows], value: rows })
                resDataArrayTemp.push(rows)
            })
            editors = sortObject(editors);
        }

        if (resData.clients) {
            let propertyNames = Object.keys(resData.clients);
            let resDataArray = [];
            let resDataArrayTemp = [];
            propertyNames.map(rows => {
                resDataArray.push({ label: resData.clients[rows], value: rows })
                resDataArrayTemp.push(rows)
            })
            if (!onlySelection) {
                resDataArray = sortObject(resDataArray);
                clients = resDataArray;
            }
            setFilterClient(resDataArrayTemp)
        }

        if (resData.qcFlags) {
            let propertyNames = Object.keys(resData.qcFlags);
            let resDataArray = [];
            let resDataArrayTemp = [];
            propertyNames.map(rows => {
                resDataArray.push({ label: resData.qcFlags[rows], value: rows })
                if (resData.qcFlags[rows] !== 'Deleted') {
                    if (tempStorageData.qcFlags && tempStorageData.qcFlags?.length) {
                        tempStorageData.qcFlags?.map(temp => {
                            resDataArrayTemp.push(temp);
                        })
                    } else {
                        resDataArrayTemp.push(rows);
                    }
                }
            })
            if (!onlySelection) {
                resDataArray = sortObject(resDataArray);
                qcFlags = resDataArray;
            }
            setFilterQcFlags(resDataArrayTemp);
        }

        let propertyNames = Object.keys(resData.formGroups);
        let resDataArray = [];
        let resDataArrayTemp = [];
        propertyNames.map(rows => {
            resDataArray.push({ label: resData.formGroups[rows], value: rows })
            resDataArrayTemp.push(rows)
        })
        if (!onlySelection) {
            resDataArray = sortObject(resDataArray);
            formGroups = resDataArray;
        }
        setFilterFormGroups(resDataArrayTemp);

        let propertyNames1 = Object.keys(resData.params);
        let resDataArray1 = [];
        let resDataArrayTemp1 = [];
        propertyNames1.map(rows => {
            if (resData.formGroups[rows] != 1) {
                resDataArray1.push({ label: rows, value: rows })
                resDataArrayTemp1.push(rows)
            }
        })
        if (!onlySelection) {
            resDataArray1 = sortObject(resDataArray1);
            setParams(resDataArray1);
        }
        setFilterParams(resDataArrayTemp1);

        let propertyNames2 = Object.keys(resData.monitors);
        let resDataArray2 = [];
        let resDataArrayTemp2 = [];
        propertyNames2.map(rows => {
            resDataArray2.push({ label: resData.monitors[rows], value: rows })
            resDataArrayTemp2.push(rows);
        })
        if (!onlySelection) {
            resDataArray2 = sortObject(resDataArray2);
            monitors = resDataArray2;
        }
        setFilterMonitor(resDataArrayTemp2);

        let propertyNames3 = Object.keys(resData.sites);
        let resDataArray3 = [];
        let resDataArrayTemp3 = [];
        propertyNames3.map(rows => {
            resDataArray3.push({ label: resData.sites[rows], value: rows })
            resDataArrayTemp3.push(rows)
        })
        if (!onlySelection) {
            resDataArray3 = sortObject(resDataArray3);
            sites = resDataArray3;
        }
        setFilterSite(resDataArrayTemp3);

        if (resData.forms) {
            let propertyNames4 = Object.keys(resData.forms);
            let resDataArray4 = [];
            let resDataArrayTemp4 = [];
            propertyNames4.map(rows => {
                resDataArray4.push({ label: resData.forms[rows], value: rows })
                resDataArrayTemp4.push(rows);
            })
            if (!onlySelection) {
                resDataArray4 = sortObject(resDataArray4);
                setForms(resDataArray4);
            }
            setFilterForm(resDataArrayTemp4);
        }
        select2Initialization();
    }

    // Handle per page selection
    const handleChangePerPage = (event, newValue) => {
        setRowsPerPage(event.target.value);
        setPage(Math.ceil(totalRows / event.target.value))
        setCurrentPage(1);
        setTimeout(() => {
            handlePagination({}, 1, event.target.value);
            // getMapData(event.target.value, newValue);
        }, 100);

    };

    // Handle pagination
    const handlePagination = async (event, newValue, rowsPerPageArgument, initialDataArgument) => {
        setBusyTabs(true);
        rowsPerPageArgument = rowsPerPageArgument ? rowsPerPageArgument : rowsPerPage;
        setRowsPerPage(rowsPerPageArgument);
        setCurrentPage(newValue);
        if (!initialDataArgument) {
            getDataPaginated(rowsPerPageArgument, newValue, false, false);
            getMapData(rowsPerPageArgument, newValue).then(() => {
                setTimeout(() => {
                    setBusyTabs(false);
                }, 1000);
            });
            return false;
        }
        initialDataArgument = initialDataArgument ? initialDataArgument : initialData;

        initialDataArgument = initialDataArgument?.map(rows => {
            // var tempIndex = tableSettingsParams.colHeaders.findIndex(rows => rows == 'meta.instanceID');
            return [
                // rows[tempIndex - 1],
                ...rows
            ]
        })

        const tempArrayPagination = [];
        initialDataArgument.map((row, indexData) => {
            var tempIndex = tableSettingsParams.colHeaders.findIndex(rows => rows == 'site');
            if (tempIndex > 0 && row[tempIndex] && row[tempIndex] == '-1.0') {
                row[tempIndex] = 'Unknown';
            }

            var tempIndex = tableSettingsParams.colHeaders.findIndex(rows => rows == 'monitor');
            if (tempIndex > 0 && row[tempIndex] && row[tempIndex] == '-1.0') {
                row[tempIndex] = 'Unknown';
            }
            tempArrayPagination.push(row);
        })
        tableSettingsParams.data = tempArrayPagination;
        setTimeout(() => {
            handsontableInitialization();
            setBusyTabs(false);
        }, 1000);
    };

    // Parse date
    const parseDateData = (dateParam) => {
        let dateLocal = new Date(dateParam).toLocaleDateString();
        const [day, month, year] = dateLocal.split('/');
        return day + '-' + month + '-' + year;
    }

    const onGetCount = async (isInitial, resetColumns, rowsPerPageArgument) => {
        if (!isInitial) {
            jQuery('.loader-container').removeClass('d-none');
        }

        rowsPerPageArgument = rowsPerPageArgument ? rowsPerPageArgument : rowsPerPage;
        var currentPageArgument = currentPageArgument ? currentPageArgument : currentPage;
        let columnData = $('[name="filter_column"]').val();
        if (columnData.length && columnData.length == 1 && columnData[0] == '') {
            columnData = [];
        }
        columnData = compulsoryColumns(columnData, columnsDataTableInitial);
        let postParam = generateFilterDataObject(0, 0, columnData, true);
        await postRequest(`data/get-count/`, postParam).then(res => {
            let total = res?.data?.data?.total;
            setSubTotalRows(total);
            setTotalRows(total);
            setPage(Math.ceil(total / rowsPerPageArgument))
        });
    };

    const onFilterModelInitial = async () => {
        let url = 'data/get-filters-data/';
        await postRequest(url, {}).then(res => {
            let propertyNames3 = Object.keys(res?.data?.data?.sites);
            let resDataArray3 = [];
            let resDataArrayTemp3 = [];
            propertyNames3.map(rows => {
                resDataArray3.push({ label: res?.data?.data?.sites[rows], value: rows })
                resDataArrayTemp3.push(rows)
            })
            if (resDataArray3.length && !allSites.length) {
                resDataArray3 = sortObject(resDataArray3);
                allSites = resDataArray3;
            }

            const resData = res?.data?.data;
            if (!allFilters?.projects) {
                allFilters = resData;
            }
        })
    }

    const onFilterModel = async (isInitial, resetColumns, module = '', oldTabValue = '', newValue = '') => {
        if (!isInitial) {
            jQuery('.loader-container').removeClass('d-none');
        }
        let url = 'data/get-filters-data/';
        const tempUrl = new URL(location);
        var currentTab = tempUrl.searchParams.get("tab") ?? 0;
        currentTab = parseInt(currentTab);
        if (currentTab == 2) {
            url = 'tasks/get-filters-data/';
        }
        if (currentTab == 3) {
            url = 'log/get-filters-data/';
        }
        var hasChanges = false;

        if (oldTabValue == 0 && newValue == 3) {
            hasChanges = true;
        }
        else if (oldTabValue == 3 && newValue == 0) {
            hasChanges = true;
        }
        else if (oldTabValue == 1 && newValue == 3) {
            hasChanges = true;
        }
        else if (oldTabValue == 3 && newValue == 1) {
            hasChanges = true;
        }
        else if (oldTabValue == 2 && newValue == 3) {
            hasChanges = true;
        }
        else if (oldTabValue == 3 && newValue == 2) {
            hasChanges = true;
        }
        else if (oldTabValue == 4 && newValue == 3) {
            hasChanges = true;
        }
        else if (oldTabValue == 3 && newValue == 4) {
            hasChanges = true;
        }
        else if (oldTabValue == 5 && newValue == 3) {
            hasChanges = true;
        }
        else if (oldTabValue == 3 && newValue == 5) {
            hasChanges = true;
        }

        else if (oldTabValue == 0 && newValue == 2) {
            hasChanges = true;
        }
        else if (oldTabValue == 2 && newValue == 0) {
            hasChanges = true;
        }
        else if (oldTabValue == 1 && newValue == 2) {
            hasChanges = true;
        }
        else if (oldTabValue == 2 && newValue == 1) {
            hasChanges = true;
        }
        else if (oldTabValue == 4 && newValue == 2) {
            hasChanges = true;
        }
        else if (oldTabValue == 2 && newValue == 4) {
            hasChanges = true;
        }
        else if (oldTabValue == 5 && newValue == 2) {
            hasChanges = true;
        }
        else if (oldTabValue == 2 && newValue == 5) {
            hasChanges = true;
        }

        else if (oldTabValue == '' && newValue == '') {
            hasChanges = true;
        }

        if (hasChanges) {
            await postRequest(url, generateFilterObject(true)).then(res => {
                const resData = res?.data?.data;
                if (isInitial) {
                    let propertyNames3 = Object.keys(res?.data?.data?.sites);
                    let resDataArray3 = [];
                    let resDataArrayTemp3 = [];
                    propertyNames3.map(rows => {
                        resDataArray3.push({ label: res?.data?.data?.sites[rows], value: rows })
                        resDataArrayTemp3.push(rows)
                    })
                    if (resDataArray3.length && !allSites.length) {
                        resDataArray3 = sortObject(resDataArray3);
                        allSites = resDataArray3;
                    }
                }
                let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
                if (resData.projects && tempStorageData.projects && tempStorageData.projects?.length) {
                    if (getFilterFlag('filter_project')) {
                        tempStorageData.projects = Object.keys(resData.projects);
                    }
                }
                if (resData.clients && tempStorageData.clients && tempStorageData.clients?.length) {
                    if (getFilterFlag('filter_client')) {
                        tempStorageData.clients = Object.keys(resData.clients);
                    }
                }
                if (resData.formGroups && tempStorageData.formGroups && tempStorageData.formGroups?.length) {
                    if (getFilterFlag('filter_form_groups')) {
                        tempStorageData.formGroups = Object.keys(resData.formGroups);
                    }
                }
                if (resData.forms && tempStorageData.forms && tempStorageData.forms?.length) {
                    if (getFilterFlag('filter_form')) {
                        tempStorageData.forms = Object.keys(resData.forms);
                    }
                }
                if (resData.sites && tempStorageData.sites && tempStorageData.sites?.length) {
                    if (getFilterFlag('filter_site')) {
                        tempStorageData.sites = Object.keys(resData.sites);
                    }
                }
                if (resData.monitors && tempStorageData.monitors && tempStorageData.monitors?.length) {
                    if (getFilterFlag('filter_monitor')) {
                        tempStorageData.monitors = Object.keys(resData.monitors);
                    }
                }
                if (resData.commenter && tempStorageData.commenter && tempStorageData.commenter?.length) {
                    if (getFilterFlag('filter_commenter')) {
                        tempStorageData.commenter = Object.keys(resData.commenter);
                    }
                }
                setLocalStorage(tempStorageData);

                if (isInitial) {
                    filters = resData;
                    projectFilters = resData;
                    reloadTheData(projectFilters, false, resetColumns);
                    initialFilters = resData;
                } else {
                    projectFilters = resData;
                }
                if (!isInitial) {
                    jQuery('.loader-container').addClass('d-none');
                }
                oldValues();
                newValues();
                setcolumns([]);
                // getColumns(false, true).then(res1 => {
                // });
            }).catch(error => {
                clearCache(error);
                if (!isInitial) {
                    jQuery('.loader-container').addClass('d-none');
                }
            });
        }
    };

    // Get columns from API
    const getColumns = async (load, getFromCookie = false) => {
        if (columns.length <= 0) {
            await postRequest(`data/get-columns/`, generateFilterObject(getFromCookie))
                .then(res => {
                    const resData = res?.data?.data;
                    // if (load) {
                    setColumnsData(resData);
                    // }
                    if (resData.columns) {
                        columnsInitial = resData.columns;
                    }
                    setMinStartDate(parseDateData(resData?.date?.start?.min));
                    setMaxStartDate(parseDateData(resData?.date?.start?.max));
                    setMinEndDate(parseDateData(resData?.date?.end?.min));
                    setMaxEndDate(parseDateData(resData?.date?.end?.max));
                    select2Initialization();
                    if (load) {
                        jQuery('.loader-container').addClass('d-none');
                    }
                    setTimeout(() => {
                        jQuery('.display-columns').find('.dropdown-toggle').click();
                    }, 100);
                }).catch(error => {
                    clearCache(error);
                });
        }
    };

    const setColumnsData = (resData) => {
        resData.columnsObj = resData.columns ? resData.columns : resData['filter-columns'];
        let propertyNames = (Array.isArray(resData.columnsObj)) ? resData.columnsObj : Object.keys(resData.columnsObj);
        let resDataArray = [];
        let resDataArrayTemp = [];
        propertyNames.map((rows, index) => {
            if (Array.isArray(resData.columnsObj)) {
                if (resData.columnsObj[index] !== '_id' && resData.columnsObj[index] !== '__id' && resData.columnsObj[index] !== 'meta_task_id' && resData.columnsObj[index] !== 'meta_subtask_id') {
                    resDataArray.push({ label: resData.columnsObj[index], value: resData.columnsObj[index] });
                    resDataArrayTemp.push(resData.columnsObj[index]);
                }
            } else {
                if (resData.columnsObj[rows] !== '_id' && resData.columnsObj[rows] !== '__id' && resData.columnsObj[rows] !== 'meta_task_id' && resData.columnsObj[rows] !== 'meta_subtask_id') {
                    resDataArray.push({ label: resData.columns[rows], value: resData.columnsObj[rows] });
                    resDataArrayTemp.push(resData.columns[rows]);
                }
            }
        })
        setcolumns(resDataArray);
        if (filterColumns.length <= 0) {
            setfilterColumns(resDataArrayTemp);
        }
        select2Initialization();
    }

    // Get map data from API
    const getMapData = async (rowsPerPageArgument, currentPageArgument, resetViewBy = false) => {
        jQuery('.loader-container').removeClass('d-none');
        jQuery('.no-record-container').addClass('d-none');
        var rowsPerPageArgument = rowsPerPageArgument ? rowsPerPageArgument : rowsPerPage;
        var currentPageArgument = currentPageArgument ? currentPageArgument : currentPage;
        let columnData = $('[name="filter_column"]').val();
        if (columnData?.length && columnData?.length == 1 && columnData[0] == '') {
            columnData = [];
        }
        columnData = compulsoryColumns(columnData, columnsDataTableInitial);
        let postParam = generateFilterDataObject(rowsPerPageArgument, currentPageArgument, columnData, true, true);
        postRequest(`map/`, postParam).then(res => {
            var response = res.data.data;
            mapData = response;
            loadMapData(response, allSites, rowsPerPageArgument, currentPageArgument, resetViewBy);
        }).catch(error => {
            mapData = [];
            jQuery('.loader-container').addClass('d-none');
            clearCache(error);
        });
    }

    // Reset filters
    const reset = async () => {
        jQuery('.loader-container').removeClass('d-none');
        setLocalStorage({});
        setLocalStorage(false, 'isSingleSiteEnabled');
        let url = new URL(location.href);
        url.searchParams.delete('site');
        localStorage.removeItem('singleSiteData');
        window.history.pushState('', '', url);

        jQuery('[name="filter_project"]').val('');
        jQuery('[name="filter_form_groups"]').val('');
        jQuery('[name="filter_form"]').val('');
        jQuery('[name="filter_client"]').val('');
        jQuery('[name="filter_site"]').val('');
        jQuery('[name="filter_monitor"]').val('');
        jQuery('[name="start_date"]').val('');
        jQuery('[name="end_date"]').val('');
        jQuery('[name="submission_id"]').val('');

        if (isLogsDataLoaded) {
            jQuery('[name="filter_editor"]').val('');
        }

        if (isCommentsDataLoaded) {
            jQuery('[name="filter_commenter"]').val('');
            jQuery('[name="comment_start_date"]').val('');
            jQuery('[name="comment_end_date"]').val('');
        }

        jQuery('[name="keyword"]').val('');
        onFilterModel(false, false, '').then(res => {
            reloadTheData(projectFilters, false, false);
        });
        if (isTableDataLoaded || isTableMapDataLoaded) {
            getData(false, false, true);
        } else if (isCommentsDataLoaded) {
            $('.reload-comments-data').click();
        } else if (isLogsDataLoaded) {
            $('.reload-log-data').click();
        } else if (isMediaDataLoaded) {
            $('.reload-media-data').click();
            setTimeout(() => {
                jQuery('.filter-media-graph-data-main').addClass('d-none');
                jQuery('.refresh-media-graph-data-main').addClass('d-none');
                jQuery('.reset-media-graph-data-main').addClass('d-none');
                $('.reload-media-data').click();
                setTimeout(() => {
                    $('.reload-media-initialization').click();
                    getData(false, false, true);
                }, 10);
            }, 1000);
        } else if (isGraphDataLoaded) {
            $('.refresh-graph-data').click();
            setTimeout(() => {
                jQuery('.filter-media-graph-data-main').addClass('d-none');
                jQuery('.refresh-media-graph-data-main').addClass('d-none');
                jQuery('.reset-media-graph-data-main').addClass('d-none');
                $('.refresh-graph-data').click();
                setTimeout(() => {
                    $('.reload-graph-initialization').click();
                    getData(false, false, true);
                }, 10);
            }, 1000);
        }
    }

    // Get data from API
    const getFilterResultAction = async (isInitial, closeModal = true) => {
        if (isTableDataLoaded || isTableMapDataLoaded) {
            getData(isInitial, closeModal);
        } else if (isCommentsDataLoaded) {
            if ($('.reload-comments-data').length) {
                hideModal();
                $('.reload-comments-data').click();
            }
        } else if (isLogsDataLoaded) {
            if ($('.reload-log-data').length) {
                hideModal();
                $('.reload-log-data').click();
            }
        } else if (isMediaDataLoaded) {
            if ($('.reload-media-data').length) {
                hideModal();
                $('.reload-media-data').click();
            }
        } else if (isGraphDataLoaded) {
            if ($('.refresh-graph-data').length) {
                hideModal();
                $('.refresh-graph-data').click();
            }
        }
    }

    const getData = async (isInitial, closeModal = true, resetViewBy = false) => {

        // jQuery('.select2-init').multiselect().trigger("multiselect:hide");
        if (isInitial) {
            jQuery('.loader-container').removeClass('d-none');
            jQuery('.no-record-container').addClass('d-none');
            var rowsPerPageArgument = 100;
            var currentPageArgument = 1;
            jQuery('.MuiPagination-ul').find('li:first > .MuiButtonBase-root').click();
            getDataPaginated(rowsPerPageArgument, currentPageArgument, false, isInitial);
            getMapData(rowsPerPageArgument, currentPageArgument, resetViewBy);
        } else {
            setTimeout(() => {
                jQuery('.loader-container').removeClass('d-none');
                jQuery('.no-record-container').addClass('d-none');
                var rowsPerPageArgument = 100;
                var currentPageArgument = 1;
                jQuery('.MuiPagination-ul').find('li:first > .MuiButtonBase-root').click();
                getDataPaginated(rowsPerPageArgument, currentPageArgument, false, isInitial, closeModal);
                getMapData(rowsPerPageArgument, currentPageArgument, resetViewBy);
            }, 1000);
        }
    };

    // Get data from API
    const getRefreshedData = async (rowsPerPageArgument, currentPageArgument, isDownload, isInitial, closeModal = true) => {
        getDataPaginated(rowsPerPageArgument, currentPageArgument, isDownload, isInitial, closeModal);
        getMapData(rowsPerPageArgument, currentPageArgument);
    }

    const getRefreshedTableData = async (rowsPerPageArgument, currentPageArgument, isDownload, isInitial, closeModal = true) => {
        onFilterModel(true, true).then(res => {
            getDataPaginated(rowsPerPageArgument, currentPageArgument, isDownload, isInitial, closeModal);
        });
    }
    const getDataPaginated = async (rowsPerPageArgument, currentPageArgument, isDownload, isInitial, closeModal = true) => {
        jQuery('.loader-container').removeClass('d-none');
        jQuery('.no-record-container').addClass('d-none');
        var rowsPerPageArgument = rowsPerPageArgument ? rowsPerPageArgument : rowsPerPage;
        var currentPageArgument = currentPageArgument ? currentPageArgument : currentPage;
        setRowsPerPage(rowsPerPageArgument);
        setCurrentPage(currentPageArgument);
        let columnData = $('[name="filter_column"]').val();
        if (columnData?.length && columnData?.length == 1 && columnData[0] == '') {
            columnData = [];
        }
        columnData = compulsoryColumns(columnData, columnsDataTableInitial);
        let postParam = generateFilterDataObject(rowsPerPageArgument, currentPageArgument, columnData, true);
        postParam['download'] = isDownload;
        if (isDownload) {
            postParam['downloadInit'] = currentPageArgument > 1 ? ((rowsPerPageArgument * (currentPageArgument - 1)) + 1) : currentPageArgument;
        }
        const getFilterData = generateFilterCountAndAvailability(columnData);
        setHasFilter(getFilterData.hasFilter);
        setFilterCount(getFilterData.totalFilters);
        if (!isInitial) {
            onGetCount(true, true, rowsPerPageArgument).then(res => {
            });
        }

        await postRequest(`data/`, postParam).then(res => {
            if (isDownload) {
                let fileResData = res?.data?.data;
                if (fileResData.filepath) {
                    jQuery('.loader-container').addClass('d-none');
                    var path = fileResData.filepath.replace(':8080', '');
                    window.open(path, '_blank');
                }
            } else {
                let resData = res?.data?.data?.data;
                columnsDataTableInitial = res?.data?.data['data-table-columns'];
                imageFields = res?.data?.data?.image_fields;
                repeatColumns = res?.data?.data?.repeat_columns;
                uneditable = uneditable.concat(imageFields);
                if (resData?.length || total > 0) {
                    let responseData = eval(resData);
                    var columnsObj = $('[name="filter_column"]').val();
                    columnsObj = compulsoryColumns(columnsDataTableInitial, columnsDataTableInitial);
                    var columnsDataTemp = arrange_column_header(columnsObj);
                    if (columnsDataTemp[0]) {
                        columnsDataTemp[0].columnSorting = { headerAction: false };
                    }
                    console.log(columnsDataTemp);

                    tableSettingsParams.colHeaders = columnsObj;
                    tableSettingsParams.columns = columnsDataTemp;
                    setInitialData(responseData);
                    setLocalStorage(responseData, 'tableData');
                    setLocalStorage(columnsObj, 'columnsObj');
                    setLocalStorage(columnsDataTemp, 'columnsDataTemp');
                    setTimeout(() => {
                        handlePagination({}, currentPageArgument, rowsPerPageArgument, responseData);
                    }, 200);
                } else {
                    setInitialData([]);
                    jQuery('.no-record-container').removeClass('d-none');
                    setTimeout(() => {
                        handlePagination({}, 1, rowsPerPageArgument, []);
                        setPage(Math.ceil(1 / rowsPerPageArgument))
                        setTotalRows(1);
                    }, 200);
                }
            }
            closeModal && hideModal();
        }).catch(error => {
            setInitialData([]);
            jQuery('.no-record-container').removeClass('d-none');
            setTimeout(() => {
                handlePagination({}, 1, rowsPerPageArgument, []);
                setPage(Math.ceil(1 / rowsPerPageArgument))
                setTotalRows(1);
                closeModal && hideModal();
                clearCache(error);
            }, 200);
        });
    }

    // Hide Modal
    const hideModal = () => {
        if (jQuery("#exampleModal")) {
            jQuery('#exampleModal').modal('hide');
            jQuery('.close-button').click();
        }
    };

    // Inital load
    React.useEffect(async () => {
        $('.pagination-container-inner').addClass('d-none');
        user = getLocalUser();
        if (user !== null) {
            var currentTab = url.searchParams.get("tab") ?? 0;
            jQuery('.loader-container').removeClass('d-none');
            onFilterModelInitial().then(res1 => {
                currentTab = parseInt(currentTab);
                if (currentTab != 1) {
                    $('.pagination-container-inner').show();
                }
                initRefreshGraphMediaAction('.refresh-media-graph-data-main', currentTab);
                if (currentTab > 0) {
                    jQuery('.loader-container').removeClass('d-none')
                }
            })
            getCommentRelatedOptions().then(() => {
                const tempUrl = new URL(location);
                var currentSubmissionId = tempUrl.searchParams.get("submissionId") ?? 0;
                if (currentSubmissionId != 0) {
                    let url = new URL(location.href);
                    url.searchParams.delete('submissionId');
                    window.history.pushState('', '', url);
                    var tempObj = { 'meta.instanceID': [currentSubmissionId], 'meta_instanceID': currentSubmissionId, };
                    tempObj['tz'] = getCurrentTimeZone();
                    postRequest(`map/info/`, tempObj).then(res => {
                        let tempObjForTasks = tempObj;
                        postRequest(`tasks/get-submission-tasks/`, tempObjForTasks).then(res1 => {
                            let commentsData = res1.data.data;
                            var details = {};
                            details = res.data.data.message[0];
                            details['total'] = commentsData?.total;
                            details['totalComments'] = commentsData?.total;
                            details['comments'] = commentsData?.tasks;
                            stock_info_fetched.push(details);
                            loadDataInCommentModal(details);
                            jQuery('#exampleAddCommentModal').modal('show');
                            select2Initialization();
                            select2InitializationForDataAndMap();
                        });
                    });
                }
            });
            getEditableDataOptions().then(() => {
                onFilterModel(true, true, '').then(res => {
                    setBusy(false);
                    oldValuesLength();
                });
                getColumns(false, true).then(res => {
                    getData(true).then(res1 => {
                        setTimeout(() => {
                            select2Initialization();
                            select2InitializationForDataAndMap();
                            currentTab = parseInt(currentTab);
                            if (currentTab > 0) {
                                jQuery('.loader-container').removeClass('d-none')
                                handleChange({}, currentTab);
                            }
                        }, 500);
                    }).then(() => {
                        setTimeout(() => {
                            $('.pagination-container-inner').removeClass('d-none');
                        }, 1000);
                    });
                });
                onGetCount(true, true, rowsPerPage).then(res => {
                });
            });
        }
    }, []);

    // Initialize multi select
    const select2Initialization = () => {
        jQuery('.select2-init').each(function () {
            var $_this = jQuery(this);
            var attr_name = $_this.attr('name');
            if (multipleDataField.find(rows => rows == attr_name)) {
                $_this.hide();
                $_this.addClass('d-none');
                if (jQuery('.multiselect-native-select').length > 0) {
                    $_this.multiselect('destroy');
                }
                $_this.multiselect({
                    buttonText: function (options, event) {
                        var selectedLength = options.length;
                        var element_name = $(event).attr('name');
                        var optionsLength = $(event).find('option').length;
                        if (optionsLength == 0) {
                            return 'No Options';
                        }
                        else if (options.length == 0) {
                            if (element_name == 'filter_column') {
                                return 'All Selected';
                            }
                            return 'None selected';
                        }
                        else if (selectedLength > 0 && selectedLength == optionsLength) {
                            return `${selectedLength} Selected`;
                        }
                        else if (optionsLength == 0) {
                            return 'No Options';
                        }
                        else if (options.length > 0) {
                            return options.length + ' selected';
                        }
                        else {
                            var selected = '';
                            options.each(function () {
                                selected += $(this).text() + ', ';
                            });
                            return selected.substr(0, selected.length - 2);
                        }
                    },
                    includeResetOption: false,
                    includeFilterClearBtn: true,
                    buttonTextAlignment: 'left',
                    resetText: "Reset all",
                    includeResetDivider: true,
                    numberDisplayed: 1,
                    includeSelectAllOption: true,
                    enableFiltering: true,
                    filterPlaceholder: 'Search for something...',
                    enableCaseInsensitiveFiltering: true,
                    maxHeight: 310,
                    dropUp: false,
                    justVisible: true,
                    templates: {
                        filter: '<div class="multiselect-filter"><div class="input-group input-group-sm p-1"><div class="input-group-prepend"><i class="input-group-text fa fa-search"></i></div><input class="form-control multiselect-search" type="text" /><div class="input-group-append"><button class="multiselect-clear-filter input-group-text" type="button"><i class="fa fa-times"></i></button></div></div></div>'
                    },
                    onDropdownShow: function (event) {
                        oldValues();
                    },
                    onDropdownHide: function (event) {
                        newValues();
                        let element_temp = jQuery(event.currentTarget).parent().find('select');
                        let element_name = element_temp.attr('name');
                        var selected = '';
                        if (element_name == 'filter_site') {
                            handleSelectionChange({ value: selected }, null, filters, element_name);
                        } else if (element_name == 'filter_monitor') {
                            handleSelectionChange({ value: selected }, null, filters, element_name);
                        } else if (element_name == 'filter_form') {
                            handleSelectionChange({ value: selected }, null, filters, element_name);
                        } else if (element_name == 'filter_column') {
                            setfilterColumns(element_temp?.val());
                        } else if (element_name == 'filter_client') {
                            handleSelectionChange({ value: selected }, null, filters, element_name);
                        } else if (element_name == 'filter_qc_flag') {
                            var brands = $_this.find('option:selected');
                            var selected = [];
                            $(brands).each(function (index, brand) {
                                selected.push([$(this).val()]);
                            });
                            setFilterQcFlags(selected);
                            handleSelectionChange({ value: selected }, null, filters, element_name);
                        } else if (element_name == 'filter_project') {
                            handleSelectionChange({ value: selected }, null, filters, element_name)
                        } else if (element_name == 'filter_form_groups') {
                            handleSelectionChange({ value: selected }, null, filters, element_name)
                        } else if (element_name == 'filter_image_fields') {
                            handleSelectionChange({ value: selected }, null, filters, element_name)
                        }
                    },
                    onChange: function (element, checked) {
                        var brands = $_this.find('option:selected');
                        var selected = [];
                        $(brands).each(function (index, brand) {
                            selected.push([$(this).val()]);
                        });
                        if (!checked) {
                            $(element[0]).prop("selected", false);
                        } else {
                            $(element[0]).prop("selected", true);
                        }
                        let element_name = $_this.attr('name');
                        if (element_name == 'filter_site') {
                            setFilterSite(selected);
                        } else if (element_name == 'filter_monitor') {
                            setFilterMonitor(selected);
                        } else if (element_name == 'filter_form') {
                            setFilterForm(selected);
                        } else if (element_name == 'filter_column') {
                            setfilterColumns(selected);
                        } else if (element_name == 'filter_client') {

                        } else if (element_name == 'filter_qc_flag') {
                            var brands = $_this.find('option:selected');
                            var selected = [];
                            $(brands).each(function (index, brand) {
                                selected.push([$(this).val()]);
                            });
                            setFilterQcFlags(selected);
                        } else if (element_name == 'filter_project') {
                            setFilterProjects(selected);
                        } else if (element_name == 'filter_form_groups') {
                        }
                    }
                });

                $($_this.next(".btn-group").find('.multiselect-search')).on('input', function () {
                    setTimeout(() => {
                        var elementName = $_this.attr('name');
                        let totalActualElements = 0;
                        console.log(allFilters);
                        console.log(elementName);
                        switch (elementName) {
                            case 'filter_site':
                                totalActualElements = allFilters?.sites?.length;
                                break;
                            case 'filter_client':
                                totalActualElements = allFilters?.clients?.length;
                                break;
                            case 'filter_project':
                                totalActualElements = allFilters?.projects?.length;
                                break;
                            case 'filter_monitor':
                                totalActualElements = allFilters?.monitors?.length;
                                break;
                            case 'filter_form_groups':
                                totalActualElements = allFilters?.formGroups?.length;
                                break;
                            case 'filter_form':
                                totalActualElements = allFilters?.forms?.length;
                                break;
                            default:
                                break;
                        }
                        var totalFilteredElements = $_this.parent().find('.multiselect-container').find('.multiselect-option').length;
                        var hiddenElements = $_this.parent().find('.multiselect-container').find('.multiselect-filter-hidden').length;
                        var finalElements = totalFilteredElements - hiddenElements;
                        console.log('$_this', $_this);
                        console.log('totalActualElements', totalActualElements);
                        console.log('totalFilteredElements', totalFilteredElements);
                        console.log('hiddenElements', hiddenElements);
                        console.log('finalElements', finalElements);
                        if ($(this).val() != '' && $_this.parent().find('[value="multiselect-all"]').is(':checked') && totalActualElements > 0 && totalActualElements == finalElements) {
                            $_this.parent().find('[value="multiselect-all"]').click();
                        }
                        else if ($(this).val() != '' && $_this.parent().find('[value="multiselect-all"]').is(':checked') && totalFilteredElements == finalElements) {
                            $_this.parent().find('[value="multiselect-all"]').click();
                        }
                    }, 10);
                });

            } else {
                $_this.selectpicker('refresh');
                $_this.selectpicker({
                    selectedText: 'cat',
                    multiple: false,
                    tags: true,
                    allowHtml: true,
                });
            }
        })
    }

    const updateDataBase = () => {
        if (hot) {
            hot.validateCells(function (result, obj) {
                if (result == true) {
                    var formGroup = $('#formgroup').val();
                    if (isEmpty(updateData) == false) {
                        updateData['FormGroup'] = formGroup;
                        jQuery('.modalL').show();
                        postRequest(`data/edit/`, JSON.stringify({ 'activity': updateData, 'actionType': "QCEdits" }))
                            .then(res => {
                                var response = res?.data;
                                if (response == "ok" || (response?.success)) {
                                    getDataPaginated(rowsPerPage, currentPage, false, false).then(res => {
                                        setTimeout(() => {
                                            Notify("info", "Successfully Edited");
                                            $('.reload-log-data').click();
                                        }, 1500);
                                    });
                                    setIsTableDataChanged(false);
                                }
                                else {
                                    Notify("error", "Error Editing");
                                }
                                $('.modalL').hide();
                            }).catch(error => {
                                $('.modalL').hide();
                                Notify("error", "Server Error");
                                clearCache(error);
                            });
                    }
                    else {
                        Notify('', 'No changes has been made');
                    }
                    updateData = {};
                }
                else {
                    Notify("error", "Data Missing");
                }
            });
        }
    }

    const openFilterModal = () => {
        select2Initialization();
        $('#exampleModal').modal('show');
    }

    const clickRefreshButton = (valueData, selector) => {
        initRefreshGraphMediaAction(selector, valueData);
    }

    const processSingleSiteFromLocal = (eventData) => {
        const tempUrl = new URL(location);
        var currentTabData = tempUrl.searchParams.get("tab") ?? 0;
        currentTabData = parseInt(currentTabData);
        let tempParam = 'table';
        if (currentTabData == 1) {
            tempParam = 'map';
        }
        processSingleSiteFromMedia(eventData, tempParam);
    }

    const select2InitializationForDataAndMap = () => {
        var $_this = jQuery('.select2-init-media-site');
        $_this.hide();
        $_this.addClass('d-none');
        if (jQuery('.multiselect-native-select').length > 0) {
            $_this.multiselect('destroy');
        }
        $_this.multiselect({
            numberDisplayed: 1,
            includeSelectAllOption: true,
            enableFiltering: true,
            filterPlaceholder: 'Search for something...',
            enableCaseInsensitiveFiltering: true,
            maxHeight: 310,
            dropUp: false,
            onDropdownHide: function (event) {
            },
            onChange: function (element, checked) {
                processSingleSiteFromLocal(element[0])
            },
        });
    }

    const repeatTable = showReapeatTable.show ?
        <RepeatTableModal
            meta_id={showReapeatTable.meta_id}
            field={showReapeatTable.column}
            show={showReapeatTable.show}
            data={showReapeatTable.data}
            headers={showReapeatTable.headers}
            colHeaders={showReapeatTable.col_headers}
            showRepeatTable={() => showRepeatTable()}
            onHide={() => setReapeatTable((prev) => ({ 'show': false, 'column': null, 'meta_id': null, 'data': null, 'headers': null }))} /> : null
    return (
        <>
            <HelmetProvider>
                <Helmet>

                    <title>Data</title>
                    <meta name="keywords" content="" />
                    <meta name="description" content="" />
                </Helmet>
            </HelmetProvider>
            <div className="home-content pb-0">

                <template id='templateForTaskComment'>
                    <div className="card border-0">
                        <div className="card-body d-flex justify-content-start align-items-start gap-2 border-0">
                            {/* <div className='d-flex justify-content-start align-items-center gap-2'>
                                <div className="circle-avatar cursor-pointer" title="" data-tooltip=""></div>
                            </div> */}
                            <div className='d-flex flex-column justify-content-start gap-2'>
                                <div className='d-flex gap-4'>
                                    <div className="name font-weight-bold" title="" data-tooltip=""></div>
                                    <div className="date" title="" data-tooltip=""></div>
                                </div>
                                <div className="comment" title="" data-tooltip=""></div>
                            </div>
                        </div>

                        <div className="card-footer d-none">
                            <div className='d-flex justify-content-between'>
                                <span className='user d-flex flex-row gap-1'>
                                </span>
                            </div>
                        </div>
                    </div>
                </template>

                <template id='templateForComment'>
                    <div className="card">
                        <div className="card-body d-flex justify-content-start align-items-center gap-2">
                            <input id='' className='radio card-radio' type='radio' value='' />
                            <p htmlFor='' className="cursor-pointer card-radio-text card-text d-flex flex-row align-items-center"></p>
                        </div>
                        <div className="card-footer">
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex gap-1'>
                                    <span className='edit-task d-flex flex-row gap-1'>

                                    </span>
                                    <span className='date d-flex flex-row gap-1 align-items-center'>

                                    </span>
                                </div>
                                <span className='user d-flex flex-row gap-1'>

                                </span>
                            </div>

                        </div>
                    </div>
                </template>


                <div className='d-none1'>

                    <div className="modal center-modal" id="exampleAddCommentModal" tabIndex="-1" aria-labelledby="exampleAddCommentModalLabel" aria-hidden="true" role="dialog">
                        <div className="modal-dialog modal-dialog-container modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title w-25">Tasks
                                        <span className="total-comments"></span>
                                        <span className="comments-site-name"></span>
                                    </h5>
                                    <div className='modal-subtitle w-75 d-flex align-items-center'>
                                        <a href="#" className='add-link-task btn btn-primary'>Add Task</a>
                                        <a href="#" className='back-link-task d-none btn btn-primary'>Back</a>
                                        <div className='border-1 w-40 px-2 d-flex align-items-center'>
                                            <label className='mb-0 strong w-25'>QC Flag: </label>
                                            <select name='qc_flag' className="update-task-qc-flag row_qc_flag qc_flag_comment form-control w-75 border-0" onChange={() => { }}>
                                                <option value="">Please Select</option>
                                                {qcFlags.map(({ value, label }, index) => <option value={value} key={'qc-flag-comment-' + index}>{label}</option>)}
                                            </select>
                                        </div>
                                        <button type="button" className='rounded-circle'
                                            data-placement="top" data-popover-content=".meta-info-table-parent" data-toggle="popover" data-trigger="focus" href="#" tabIndex="0">
                                            &nbsp;<i className="fa fa-info"></i>&nbsp;
                                        </button>
                                        <button type="button" className="pl-0 ml-0 close close-comment" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="modal-body text-left pb-0">
                                    <div className='w-100 bg-gray meta-info-table-parent'>
                                        <div className='w-100 bg-gray meta-info-table'>
                                            <table className="table table-borderless comments-table-container">
                                                <thead>
                                                    <tr>
                                                        <th>Instance ID </th>
                                                        <td className="row_instance_id word-break"></td>
                                                    </tr>

                                                    <tr>
                                                        <th>Site </th>
                                                        <td className="row_site word-break"></td>
                                                    </tr>

                                                    <tr>
                                                        <th>Coordinates </th>
                                                        <td className="row_coordinates"></td>
                                                    </tr>

                                                    <tr>
                                                        <th>Submitted </th>
                                                        <td className="row_submitted"></td>
                                                    </tr>

                                                    <tr>
                                                        <th>Project </th>
                                                        <td className="row_project"></td>
                                                    </tr>

                                                    <tr>
                                                        <th>Monitor </th>
                                                        <td className="row_monitor"></td>
                                                    </tr>

                                                    <tr className='d-none'>
                                                        <th>QC Flag </th>
                                                        <td className="row_qc_flag"></td>
                                                    </tr>

                                                    <tr>
                                                        <th>Form </th>
                                                        <td className="row_form word-break"></td>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='tasks-container'>
                                        <div className="no-record-comments-container-modal d-none">
                                            <p className='w-100 w-full text-xl-center'>There are no tasks to display.</p>
                                        </div>
                                        <div className="comments-container d-none">
                                            <div className='row gap-3 w-100 bg-gray table-scroll tasks-parent-container flex-column flex-md-row justify-content-between mb-3'>
                                                <div className='col float-left information'>
                                                    <div className='information-header'>
                                                        <h6>Information Only</h6>
                                                    </div>
                                                    <div className='information-data d-flex flex-column gap-2'>

                                                    </div>
                                                </div>
                                                <div className='col float-left in-progress'>
                                                    <div className='in-progress-header'>
                                                        <h6>Pending/In Progress/Question</h6>
                                                    </div>
                                                    <div className='in-progress-data d-flex flex-column gap-2'>

                                                    </div>
                                                </div>
                                                <div className='col float-left completed d-flex flex-column'>
                                                    <div className='completed-header'>
                                                        <h6>Complete</h6>
                                                    </div>
                                                    <div className='completed-data d-flex flex-column gap-3'>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer justify-content-between tasks-add-new-user-container d-none border-top-0">
                                    <div className='w-50'>
                                        <table className="table table-borderless comments-table-container">
                                            <thead>
                                                <tr>
                                                    <td className='d-flex gap-2'><strong className='font-bold text-bold strong'>Assigner: </strong><span className='author'>{user?.name}</span></td>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>

                                    <div className='d-flex gap-2 w-100'>
                                        <div className='w-50'>
                                            <table className="table table-borderless comments-table-container">
                                                <thead>
                                                    <tr>
                                                        <th><label>Recipient</label></th>
                                                        <td className='notification_recipient_container'>
                                                            <select name='notification_recipient' className="add_row_notification_recipient notification_recipient_comment form-control w-100 select2-init-comment" onChange={() => { }}
                                                                multiple={true}>
                                                                {notificationRecipients.map(({ value, label }, index) => <option value={value} key={'notification-recipient-comment-' + index}>{label}</option>)}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                </thead>
                                            </table>

                                            <div className="w-100 justify-content-end">
                                                <button type="button" className="ml-2 btn btn-primary update-comment-add-user-button float-right pull-right">Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between tasks-add-container d-none border-top-0">
                                    <div className='w-50'>
                                        <table className="table table-borderless comments-table-container">
                                            <thead>
                                                <tr>
                                                    <td className='d-flex gap-2'><strong className='font-bold text-bold strong'>Assigner: </strong><span className='author'>{user?.name}</span></td>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                    <div className='d-flex gap-2 w-100 md-flex-col'>
                                        <div className='w-50'>
                                            <table className="table table-borderless comments-table-container">
                                                <thead>
                                                    <tr>
                                                        <th><label>Status <em className="text-red error">*</em></label></th>
                                                        <td>
                                                            <select name='comment_status' className="row_comment_status comment_status_comment form-control w-100" onChange={() => { }}>
                                                                <option value="">Please Select</option>
                                                                {taskStatuses.map(({ value, label }, index) => <option value={value} key={'comment-status-comment-' + index}>{label}</option>)}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th><label>Recipient</label></th>
                                                        <td className='notification_recipient_container'>
                                                            <select name='notification_recipient' className="row_notification_recipient notification_recipient_comment form-control w-100 select2-init-comment" onChange={() => { }}
                                                                multiple={true}>
                                                                {notificationRecipients.map(({ value, label }, index) => <option value={value} key={'notification-recipient-comment-' + index}>{label}</option>)}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th><label>Priority</label></th>
                                                        <td>
                                                            <select name='task_priority' className="row_task_priority task_priority_comment form-control w-100" onChange={() => { }}>
                                                                <option value="">Please Select</option>
                                                                {taskPriorities.map(({ value, label }, index) => <option value={value} key={'task-priority-comment-' + index}>{label}</option>)}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th><label>Due</label></th>
                                                        <td>
                                                            <input name='task_due_date' className="row_task_due_date task_task_due_date_comment form-control w-100" type="date" data-date-format='dd-mm-YYYY' />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Detail <em className="text-red error">*</em></th>
                                                        <td>
                                                            <textarea className="form-control w-100 comment-text"></textarea>
                                                        </td>
                                                    </tr>
                                                </thead>
                                            </table>

                                            <div className="w-100 justify-content-end">
                                                <button type="button" className="ml-2 btn btn-primary add-comment-button float-right pull-right">Add</button>
                                                <button type="button" className="ml-2 btn btn-primary update-comment-button float-right pull-right">Update</button>
                                            </div>
                                        </div>
                                        <div className='w-50 d-none comments-parent-table-container manage-comments-container'>
                                            <table className="table table-borderless comments-table-container">
                                                <thead>
                                                    <tr>
                                                        <th>Comment <em className="text-red error">*</em></th>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <textarea className="form-control w-100 task-comment-text"></textarea>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th><label>Recipient</label></th>
                                                    </tr>
                                                    <tr>
                                                        <td className='notification_recipient_container_comment'>
                                                            <select name='notification_recipient_comment' className="add_row_notification_recipient_comment notification_recipient_task_comment form-control w-100 select2-init-comment" onChange={() => { }}
                                                                multiple={true}>
                                                                {notificationRecipients.map(({ value, label }, index) => <option value={value} key={'notification-recipient-task-comment-' + index}>{label}</option>)}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                </thead>
                                            </table>

                                            <div className="w-100 justify-content-end add-comment-container clear clearfix">
                                                <input type='hidden' className='comment_id' data-comment-id='' value='' />
                                                <button type="button" className="ml-2 btn btn-primary add-task-comment-button float-right pull-right mb-2">Add Comment</button>
                                            </div>

                                            <table className="table comments-table-container task-comments gap-3 d-flex flex-column mt-2">

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal right-modal" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-container">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Filters</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => hideModal()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body text-left">
                                    {isGraphDataLoaded ?
                                        <div className='additional-filter'>
                                            <div className='w-100'>
                                                <div className="form-group d-flex flex-row align-items-center gap-2">
                                                    <label htmlFor="exampleFormControlSelect9" className='w-25'>Param</label>
                                                    <select name='filter_param' className="w-75 select2-init" multiple={true} value={filterParams} onChange={() => { }}>
                                                        {params.map(({ value, label }, index) => <option value={value} key={'param-' + index}>{label}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                        </div> : <></>
                                    }
                                    {isLogsDataLoaded ?
                                        <div className='additional-filter'>
                                            <div className='w-100'>
                                                <div className="form-group d-flex flex-row align-items-center gap-2">
                                                    <label htmlFor="exampleFormControlSelect11" className='w-25'>Log Start Date</label>
                                                    <Form.Control type="date" name="log_start_date" placeholder="Log Start Date" className='form-control w-75' min={minStartDate} max={maxStartDate} />
                                                </div>
                                            </div>

                                            <div className='w-100'>
                                                <div className="form-group d-flex flex-row align-items-center gap-2">
                                                    <label htmlFor="exampleFormControlSelect11" className='w-25'>Log End Date</label>
                                                    <Form.Control type="date" name="log_end_date" placeholder="Log End Date" className='form-control w-75' min={minEndDate} max={maxEndDate} />
                                                </div>
                                            </div>

                                            <div className='w-100 editor_container'>
                                                <div className="form-group d-flex flex-row align-items-center gap-2">
                                                    <label htmlFor="exampleFormControlSelect9" className='w-25'>Editor</label>
                                                    <select name='filter_editor' className="w-75 select2-init" multiple={true} onChange={() => { }}>
                                                        {editors.map(({ value, label }, index) => <option value={value} key={'editor-' + index}>{label}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                    {isMediaDataLoaded ?
                                        <>
                                            <div className='additional-filter'>
                                                <div className='w-100'>
                                                    <div className="form-group d-flex flex-row align-items-center gap-2">
                                                        <label htmlFor="exampleFormControlSelect9" className='w-25'>Image Columns</label>
                                                        <select name='filter_image_fields' className="w-75 select2-init" multiple={true} onChange={() => { }}>
                                                            {imageFields?.map((value, index) => <option value={value} key={'image-field-' + index}>{value}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : <></>
                                    }
                                    {isCommentsDataLoaded ?
                                        <div className='additional-filter'>
                                            <div className='w-100'>
                                                <div className="form-group d-flex flex-row align-items-center gap-2">
                                                    <label htmlFor="exampleFormControlSelect11" className='w-25'>Comment Start Date</label>
                                                    <Form.Control type="date" name="comment_start_date" placeholder="Comment Start Date" className='form-control w-75' min={minStartDate} max={maxStartDate} />
                                                </div>
                                            </div>


                                            <div className='w-100'>
                                                <div className="form-group d-flex flex-row align-items-center gap-2">
                                                    <label htmlFor="exampleFormControlSelect11" className='w-25'>Comment End Date</label>
                                                    <Form.Control type="date" name="comment_end_date" placeholder="Comment End Date" className='form-control w-75' min={minEndDate} max={maxEndDate} />
                                                </div>
                                            </div>

                                            <div className='w-100 commenter-container'>
                                                <div className="form-group d-flex flex-row align-items-center gap-2">
                                                    <label htmlFor="exampleFormControlSelect9" className='w-25'>Assigner</label>
                                                    <select name='filter_commenter' className="w-75 select2-init" multiple={true} onChange={() => { }}>
                                                        {commenter.map(({ value, label }, index) => <option value={value} key={'commenter-' + index}>{label}</option>)}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className='w-100 commenter-container'>
                                                <div className="form-group d-flex flex-row align-items-center gap-2">
                                                    <label htmlFor="exampleFormControlSelect9" className='w-25'>Task Priority</label>
                                                    <select name='filter_task_priority' className="w-75 select2-init" multiple={true} onChange={() => { }}>
                                                        {task_priorities_select.map(({ value, label }, index) => <option value={value} key={'task_priorities_select-' + index}>{label}</option>)}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className='w-100 commenter-container'>
                                                <div className="form-group d-flex flex-row align-items-center gap-2">
                                                    <label htmlFor="exampleFormControlSelect9" className='w-25'>Task Recipient</label>
                                                    <select name='filter_task_recipient' className="w-75 select2-init" multiple={true} onChange={() => { }}>
                                                        {task_recipients_select.map(({ value, label }, index) => <option value={value} key={'task_recipients_select-' + index}>{label}</option>)}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className='w-100'>
                                                <div className="form-group d-flex flex-row align-items-center gap-2">
                                                    <label htmlFor="exampleFormControlSelect11" className='w-25'>Keyword</label>
                                                    <Form.Control type="text" name="keyword" placeholder="Keyword" className='form-control w-75' />
                                                </div>
                                            </div>
                                        </div>

                                        : <></>
                                    }

                                    {checkIfClientAccess() ?

                                        <div className='w-100'>
                                            <div className="form-group d-flex flex-row align-items-center gap-2">
                                                <label htmlFor="exampleFormControlSelect2" className='w-25'>Client</label>
                                                <select name='filter_client' className="w-75 select2-init" multiple={true} onChange={() => { }} value={filterClient}>
                                                    {clients.map(({ value, label }, index) => <option value={value} key={'client-' + index}>{label}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }

                                    {checkIfProjectAccess() ?
                                        <div className='w-100'>
                                            <div className="form-group d-flex flex-row align-items-center gap-2">
                                                <label htmlFor="exampleFormControlSelect1" className='w-25'>Project</label>
                                                <select name='filter_project' id="project" className="w-75 select2-init" multiple={true} onChange={() => { }} value={filterProjects}>
                                                    {projects.map(({ value, label }, index) => <option value={value} key={'project-' + index}>{label}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }

                                    <div className={isMediaDataLoaded || isGraphDataLoaded ? 'w-100' : 'w-100'}>
                                        <div className="form-group d-flex flex-row align-items-center gap-2">
                                            <label htmlFor="exampleFormControlSelect3" className='w-25'>Site</label>
                                            <select name='filter_site' className="w-75 select2-init" value={filterSite} multiple={isMediaDataLoaded || isGraphDataLoaded ? false : true} onChange={() => { }}>
                                                {sites.map(({ value, label }, index) => <option value={value} key={'site-' + index}>{label}</option>)}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='w-100'>
                                        <div className="form-group d-flex flex-row align-items-center gap-2">
                                            <label htmlFor="exampleFormControlSelect4" className='w-25'>Monitor</label>
                                            <select name='filter_monitor' className="w-75 select2-init" value={filterMonitor} multiple={true} onChange={() => { }}>
                                                {monitors.map(({ value, label }, index) => <option value={value} key={'monitor-' + index}>{label}</option>)}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='w-100'>
                                        <div className="form-group d-flex flex-row align-items-center gap-2">
                                            <label htmlFor="exampleFormControlSelect6" className='w-25'>Form Group</label>
                                            <select name='filter_form_groups' className="w-75 select2-init" multiple={true} onChange={() => { }} value={filterFormGroups}>
                                                {/* <option value="">Please Select</option> */}
                                                {formGroups.map(({ value, label }, index) => <option value={value} key={'form-group-' + index}>{label}</option>)}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='w-100'>
                                        <div className="form-group d-flex flex-row align-items-center gap-2">
                                            <label htmlFor="exampleFormControlSelect5" className='w-25'>Form</label>
                                            <select name='filter_form' id="form" className="w-75 select2-init" value={filterForm} multiple={true} onChange={() => { }}>
                                                {forms.map(({ value, label }, index) => <option value={value} key={'form-' + index}>{label}</option>)}
                                            </select>
                                        </div>
                                    </div>

                                    <div className={isTableDataLoaded || isTableMapDataLoaded ? 'w-100 ' : 'w-100 d-none '}>
                                        <div className="form-group d-flex flex-row align-items-center gap-2 display-columns" onClick={() => getColumns(true, true)}>
                                            <label htmlFor="exampleFormControlSelect7" className='w-25'>Display Columns</label>
                                            <select name='filter_column' className="w-75 select2-init" value={filterColumns} multiple={true} onChange={() => { }}>
                                                {
                                                    columns?.length <= 0 ? <option value={''}>All columns</option> : columns.map(({ value, label }, index) => <option value={value} key={'column-' + index}>{label}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className='w-100'>
                                        <div className="form-group d-flex flex-row align-items-center gap-2">
                                            <label htmlFor="exampleFormControlSelect10" className='w-25'>Start Date</label>
                                            <Form.Control type="date" name="start_date" placeholder="Start Date" className='form-control w-75' min={minStartDate} max={maxStartDate} />
                                        </div>
                                    </div>

                                    <div className='w-100'>
                                        <div className="form-group d-flex flex-row align-items-center gap-2">
                                            <label htmlFor="exampleFormControlSelect11" className='w-25'>End Date</label>
                                            <Form.Control type="date" name="end_date" placeholder="End Date" className='form-control w-75' min={minEndDate} max={maxEndDate} />
                                        </div>
                                    </div>


                                    <div className='w-100'>
                                        <div className="form-group d-flex flex-row align-items-center gap-2">
                                            <label htmlFor="exampleFormControlSelect8" className='w-25'>QC Flag</label>
                                            <select name='filter_qc_flag' className="w-75 select2-init" value={filterQcFlags} multiple={true} onChange={() => { }}>
                                                {qcFlags.map(({ value, label }, index) => <option value={value} key={'qc-flag-' + index}>{label}</option>)}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='w-100'>
                                        <div className="form-group d-flex flex-row align-items-center gap-2">
                                            <label htmlFor="exampleFormControlSelect11" className='w-25'>Submission ID</label>
                                            <Form.Control type="text" name="submission_id" placeholder="Submission ID" className='form-control w-75' />
                                        </div>
                                    </div>

                                    <div className='w-100'>
                                        <div className="form-group d-flex flex-row align-items-center gap-2">
                                            <div className="form-check form-switch w-100 pl-0">
                                                <label className="form-check-label w-40" htmlFor="flexSwitchCheckChecked">Show UTC Date Time</label>
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" name="is_utc_only" value="0" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer justify-content-between">
                                    <div>
                                        <button type="button" className="btn btn-secondary reset-filter-button" onClick={reset}>Reset</button>
                                    </div>
                                    <div>
                                        {/* <button type="button" className="btn btn-primary apply-filter-btn" onClick={() => getData(false)}>Filter Results</button> */}
                                        <button type="button" className="btn btn-primary apply-filter-btn" onClick={() => getFilterResultAction(false)}> {valueDataState == 4 ? "View Media" : "Filter Results"}</button>
                                        <button type="button" className="ml-2 btn btn-secondary close-button" data-dismiss="modal" onClick={() => hideModal()}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modalL"></div>
                <CirclesWithBar
                    height="100"
                    width="100"
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass="flex items-center w-full loader-container d-none"
                    visible={true}
                    outerCircleColor=""
                    innerCircleColor=""
                    barColor=""
                    ariaLabel='circles-with-bar-loading'
                />
                <div className='data_header d-flex flex-column flex-xxl-row gap-2 justify-content-between'>
                    <div className="px-2 mobile-tabs">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <div className="">
                                <Tabs value={valueDataState} onChange={handleChange} aria-label="basic tabs example" disabled={busyTabs}>
                                    {
                                        process.env.REACT_APP_TABLE_TAB == 1 ?
                                            <Tab label="Table" {...a11yProps(0, 'table-main-tab')} disabled={busyTabs} />
                                            : ''
                                    }
                                    {
                                        process.env.REACT_APP_MAP_TAB == 1 ?
                                            <Tab label="Map" {...a11yProps(1, 'map-main-tab')} disabled={busyTabs} />
                                            : ''
                                    }
                                    {
                                        process.env.REACT_APP_COMMENT_TAB == 1 ?
                                            <Tab label="Tasks" {...a11yProps(2, 'comments-main-tab')} disabled={busyTabs} />
                                            : ''
                                    }
                                    {
                                        process.env.REACT_APP_LOG_TAB == 1 ?
                                            <Tab label="Log" {...a11yProps(3, 'log-main-tab')} disabled={busyTabs} />
                                            : ''
                                    }
                                    {
                                        process.env.REACT_APP_MEDIA_TAB == 1 ?
                                            <Tab label="Media" {...a11yProps(4, 'media-main-tab')} disabled={busyTabs} />
                                            : ''
                                    }
                                    {
                                        process.env.REACT_APP_GRAPH_TAB == 1 ?
                                            <Tab label="Graph" {...a11yProps(5, 'graph-main-tab')} disabled={busyTabs} />
                                            : ''
                                    }
                                </Tabs>
                            </div>
                        </Box>
                    </div>
                    {valueData <= 1 || valueData == 4 || valueData == 5 ?
                        <div className='pagination-block d-flex flex-column flex-md-row align-items-center gap-5 justify-content-end pb-3 px-2 px-md-0 pb-xxl-0'>

                            <div className="pagination-mobile d-flex d-md-flex-row align-items-center gap-5">
                                {valueData <= 1 ?
                                    <Pagination
                                        className='pagination-container-inner'
                                        selected={false}
                                        size={'medium'}
                                        count={page}
                                        defaultPage={currentPage}
                                        page={currentPage}
                                        shape="rounded"
                                        variant="outlined"
                                        color="primary"
                                        showFirstButton
                                        showLastButton
                                        siblingCount={0}
                                        boundaryCount={1}
                                        disabled={busyTabs}
                                        onChange={handlePagination} />
                                    : <></>
                                }
                                <div className="sorting-pagination d-flex d-md-flex-row align-items-center gap-lg-5 gap-3">
                                    {valueData <= 1 ?
                                        <>
                                            <select className='pagination-container-inner form-control w-10' onChange={handleChangePerPage} value={rowsPerPage}>
                                                <option value={100}>100</option>
                                                <option value={200}>200</option>
                                                <option value={500}>500</option>
                                                <option value={1000}>1000</option>
                                            </select>
                                            <strong className='pagination-container-inner'>{currentPage > 1 ? ((rowsPerPage * (currentPage - 1)) + 1) : currentPage} to {((totalRows > rowsPerPage * currentPage) ? rowsPerPage * currentPage : totalRows)} of {totalRows}</strong>
                                        </>
                                        : <></>
                                    }

                                    <Box className={'float-right'}>
                                        <Card sx={{ Width: '100%', overflow: 'inherit' }} className=''>
                                            <CardContent className={'d-lg-flex d-md-block d-md-flex-row justify-content-start align-items-center lg-gap-2 pl-0 pt-0 pagination-container float-right table-filter'}>
                                                <div className='d-lg-flex justify-content-between align-items-center w-100 gap-2'>

                                                    {valueData <= 1
                                                        ?
                                                        <div className={'select-site-container mt-3 ' + ((tempStorageDataState?.singleSiteMode == true && tempStorageDataState?.singleSite != '' && tempStorageDataState?.singleSite != 'undefined') ? 'd-none' : 'd-none')}>
                                                            <div className="form-group d-flex flex-row align-items-center gap-2 filter-site-media">
                                                                <select name='filter_site_media' className="w-100 select2-init-media-site form-control"
                                                                    onChange={() => processSingleSiteFromLocal(event)} >
                                                                    <option data-site='' data-site-name='' value='' key='site-first'>Please select site</option>
                                                                    {allSites.map(({ value, label }, index) => <option
                                                                        data-site={value} data-site-name={label}
                                                                        value={value + '|||' + label} key={'site-' + index}>{label}</option>)}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                    }

                                                    {valueData != 4 && valueData != 5 ?
                                                        <div className='d-flex d-flex-row'>

                                                            <button type="button" className="refresh-map-table-data float-right btn btn-primary shadow-none mr-2 fa fa-reload" disabled={busy} title="Get Latest Data" onClick={() => getRefreshedData(rowsPerPage, currentPage, false, false)}>
                                                                <CachedIcon />
                                                            </button>

                                                            <button type="button" className="d-none refresh-table-data float-right btn btn-primary shadow-none mr-2 fa fa-reload" disabled={busy} title="Get Latest Data" onClick={() => getRefreshedTableData(rowsPerPage, currentPage, false, false)}>
                                                                <CachedIcon />
                                                            </button>

                                                            <button type="button" className="float-right btn btn-primary shadow-none mr-2" title="Filter" disabled={busy} onClick={() => openFilterModal()}>
                                                                <FilterAltIcon />
                                                            </button>

                                                            <button type="button" className="float-right btn btn-primary shadow-none mr-0 fa fa-reload" disabled={!hasFilter || busy} title="Reset Filter" onClick={() => reset()}>
                                                                <ResetIcon />
                                                                {
                                                                    filterCount > 0 ?
                                                                        <Badge bg="danger" className='filter-count-badge'>
                                                                            <span>{filterCount}</span>
                                                                        </Badge>
                                                                        :
                                                                        <></>
                                                                }
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className='d-flex d-flex-row'>
                                                            <button type="button" className="d-none refresh-table-data float-right btn btn-primary shadow-none mr-2 fa fa-reload d-none" disabled={busy} title="Get Latest Data" onClick={() => getRefreshedTableData(rowsPerPage, currentPage, false, false)}>
                                                                <CachedIcon />
                                                            </button>


                                                            {valueData == 5 ?
                                                                <>
                                                                    <button type="button" className="refresh-graph-data-main float-right btn btn-primary shadow-none mr-2 fa fa-reload d-none" title="Get Latest Data">
                                                                        <CachedIcon />
                                                                    </button>
                                                                    <button id="exportdiv" className="float-right btn btn-primary shadow-none mr-2 d-none export-graph-data-main" type="button">Download</button>
                                                                    <div id="exportdiv1" className="export-graph-data-main d-none"></div>
                                                                </>
                                                                : <></>
                                                            }

                                                            <button type="button" className="filter-media-graph-data-main float-right btn btn-primary shadow-none mr-2 d-none" title="Filter" onClick={() => openFilterModal()}>
                                                                <FilterAltIcon />
                                                            </button>

                                                            <button type="button" className="refresh-media-graph-data-main float-right btn btn-primary shadow-none mr-2 fa fa-reload d-none" title="Get Latest Data" onClick={() => clickRefreshButton(valueData, '.refresh-media-graph-data-main')}>
                                                                <CachedIcon />
                                                            </button>

                                                            <button type="button" className="reset-media-graph-data-main float-right btn btn-primary shadow-none mr-4 fa fa-reload d-none" disabled={!hasFilter} title="Reset Filter" onClick={() => reset()}>
                                                                <ResetIcon />
                                                                {
                                                                    filterCount > 0 ?
                                                                        <Badge bg="danger" className='filter-count-badge'>
                                                                            <span>{filterCount}</span>
                                                                        </Badge>
                                                                        :
                                                                        <></>
                                                                }
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </div>
                            </div>
                        </div>
                        : <></>
                    }
                </div>

                {
                    process.env.REACT_APP_TABLE_TAB == 1 ?
                        <TabPanel value={valueDataState} index={0} className="pl-0 tab-pane-content min-h-53vh">
                            {isTableDataLoaded || isInitialTableDataLoaded ?
                                <div>
                                    <div className="px-2">
                                        {!isTableDataChanged ?
                                            <button type="button" className={'btn btn-primary shadow-none updateData mb-2' + (checkIfWriteAccess() ? ' ' : ' d-none')} onClick={updateDataBase} disabled>
                                                Save Changes
                                            </button>
                                            :
                                            <button type="button" className={'btn btn-primary shadow-none updateData mb-2' + (checkIfWriteAccess() ? ' ' : ' d-none')} onClick={updateDataBase} disabled={!checkIfWriteAccess()}>
                                                Save Changes
                                            </button>
                                        }

                                        {initialData.length > 0 ?
                                            <button type="button" className="ml-2 btn btn-primary shadow-none updateData mb-2" onClick={() => getDataPaginated(rowsPerPage, currentPage, true, false)}>
                                                Download
                                            </button>
                                            : null}
                                    </div>

                                    <div className={'overflow-auto1 table-fit table-custom-height'} id="handsontableContainerParent">
                                        <div className={'overflow-auto1'} id="handsontableContainer"></div>
                                    </div>
                                    <div className='no-record-container d-none mt-5'>
                                        <p className='w-100 w-full text-xl-center'>There are no results for your filter selections</p>
                                    </div>
                                </div>
                                : ''}
                        </TabPanel>
                        : ''
                }
                {
                    process.env.REACT_APP_MAP_TAB == 1 ?
                        <TabPanel value={valueDataState} index={1} className="min-h-53vh">
                            <DataMap initialFilters={initialFilters}
                                valueDataState={valueDataState}
                                setValueDataState={setValueDataState}
                                initialData={initialData}
                                rowsPerPage={rowsPerPage}
                                currentPage={currentPage}
                                setTotalRowsCustom={setTotalRowsCustom}
                                totalRows={totalRows}
                                subTotalRows={subTotalRows}
                                setPageCustom={setPageCustom}
                                setCurrentPage={setCurrentPage}
                                allSites={allSites}
                            ></DataMap>
                        </TabPanel>
                        : ''
                }
                {
                    process.env.REACT_APP_COMMENT_TAB == 1 ?
                        <TabPanel value={valueDataState} index={2} className=" min-h-53vh">
                            {isCommentsDataLoaded || isInitialCommentsDataLoaded ?
                                <DataComments initialFilters={initialFilters} valueDataState={valueDataState} setValueDataState={setValueDataState}></DataComments>
                                : ''}
                        </TabPanel>
                        : ''
                }
                {
                    process.env.REACT_APP_LOG_TAB == 1 ?
                        <TabPanel value={valueDataState} index={3} className=" min-h-53vh other-tab-header">
                            {isLogsDataLoaded || isInitialLogsDataLoaded ?
                                <DataLog initialFilters={initialFilters} valueDataState={valueDataState} setValueDataState={setValueDataState}></DataLog>
                                : ''}
                        </TabPanel>
                        : ''
                }
                {
                    process.env.REACT_APP_MEDIA_TAB == 1 ?
                        <TabPanel value={valueDataState} index={4} className=" min-h-53vh">
                            {isMediaDataLoaded || isInitialMediaDataLoaded ?
                                <DataMedia initialFilters={initialFilters} valueDataState={valueDataState} setValueDataState={setValueDataState} allSites={allSites}></DataMedia>
                                : ''}
                        </TabPanel>
                        : ''
                }
                {
                    process.env.REACT_APP_GRAPH_TAB == 1 ?

                        <TabPanel value={valueDataState} index={5} className=" min-h-53vh">
                            {isGraphDataLoaded || isInitialGraphDataLoaded ?
                                <DataGraph initialFilters={initialFilters} valueDataState={valueDataState} setValueDataState={setValueDataState} allSites={allSites}></DataGraph>
                                : ''}
                        </TabPanel>
                        : ''
                }
                {repeatTable}
            </div >
        </>
    );
};
