import Box from '@mui/material/Box';
import api from '../../api';
import 'lightbox2/src/css/lightbox.css';
import 'lightbox2/src/js/lightbox.js';
let old_projects_length = [];
let old_image_fields_length = [];
let old_formGroups_length = [];
let old_forms_length = [];
let old_clients_length = [];
let old_sites_length = [];
let old_monitors_length = [];
let old_qc_flags_length = [];
let old_commenter_length = [];
let old_editor_length = [];
let old_projects = [];
let old_image_fields = [];
let old_formGroups = [];
let old_forms = [];
let old_clients = [];
let old_sites = [];
let old_monitors = [];
let old_qc_flags = [];
let new_projects = [];
let new_image_fields = [];
let new_formGroups = [];
let new_forms = [];
let new_clients = [];
let new_sites = [];
let new_monitors = [];
let new_qc_flags = [];
export const tableSettingsParamsForMapInfoWindow = {
    data: [],
    allowEmpty: true,
    fillHandle: true,
    columns: [],
    stretchH: 'all',
    colHeaders: [],
    columnSorting: true,
    sortIndicator: true,
    filters: true,
    dropdownMenu: true,
    dropdownMenu: [
        'undo',
        'redo',
        'remove_col',
        '---------',
        'make_read_only',
        '---------',
        'alignment',
    ],
    dropdownMenu: {
        items: {
            'undo': {
                key: 'undo',
                name: 'Undo',
            },
            'redo': {
                key: 'redo',
                name: 'Redo',
            },
            'remove_col': {
                key: 'remove_col',
                name: 'Remove Column',
            },
            'make_read_only': {
                key: 'make_read_only',
                name: 'Make Readonly',
            },
            'alignment': {
                key: 'alignment',
                name: 'Alignment',
            }
        }
    },
    width: '100%',
    height: '25vh',
    scrollH: 'auto',
    scrollV: 'auto',
    stretchH: 'last',
    minSpareRows: 0,
    minWidth: '100%',
    rowHeaders: true,
    contextMenu: ['undo', 'redo', 'copy', 'cut', 'make_read_only', 'alignment'],
    startRows: 5,
    startCols: 5,
    manualRowResize: true,
    manualColumnResize: true,
    manualRowMove: true,
    licenseKey: 'non-commercial-and-evaluation',
    afterChange: function (changes, source) {
    },
    afterRender: function (isForced) {
    },
    cells: function (row, col, prop) {
        var cellProperties = {};
        cellProperties.readOnly = true;
        return cellProperties;
    },
};

export const userToken = {};
export const Notify = (type, msg) => {
    console.log(type);
    console.log(msg);
    var msg_type = "";
    if (type == "error") {
        msg_type = "warning";
    }
    else {
        msg_type = "info";
    }
    jQuery.notifyClose()
    jQuery.notify({
        icon: 'pe-7s-user',
        message: msg
    }, {
        autoHide: true,
        type: msg_type,
        // delay: 100,
        // timer: 5000,
        hideAnimation: 'slideUp',
        hideDuration: 200,
        gap: 2,
        clickToHide: true,
        autoHideDelay: 5000,
        placement: {
            from: "top",
            align: "center"
        },
        spacing: 20,
        bottom: 20,
        top: 40,
        z_index: 2000
    });
}

export const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box sx={{ p: 3 }}>
                <div>{children}</div>
            </Box>
        </div>
    );
}

export const a11yProps = (index, className) => {
    return {
        id: `simple-tab-${index}`,
        className: className,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const getLocalUser = () => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    return user;
}

export const setLocalStorage = (data, key = 'filter_data_main_table') => {
    data = JSON.stringify(data);
    let currentStorageItem = localStorage.getItem(key);
    if (currentStorageItem) {
        localStorage.removeItem(key)
    }
    localStorage.setItem(key, data)
}

export const setMapType = (data, key = 'mapType') => {
    let currentStorageItem = localStorage.getItem(key);
    if (currentStorageItem) {
        localStorage.removeItem(key)
    }
    localStorage.setItem(key, data)
}

export const getMapType = (data, key = '') => {
    const storageData = localStorage.getItem(data);
    return storageData;
}

export const getLocalStorage = (data, key = '') => {
    const storageData = localStorage.getItem(data);
    const storageDataParsed = JSON.parse(storageData);
    let returnData = storageDataParsed;
    key = key.replace('[name="', '');
    key = key.replace('"]', '');
    if (key) {
        switch (key) {
            case 'filter_project':
                if (storageDataParsed?.projects) {
                    returnData = storageDataParsed?.projects;
                }
                break;
            case 'filter_form_groups':
                if (storageDataParsed?.formGroups) {
                    returnData = storageDataParsed?.formGroups;
                }
                break;
            case 'filter_form':
                if (storageDataParsed?.forms) {
                    returnData = storageDataParsed?.forms;
                }
                break;
            case 'filter_site':
                if (storageDataParsed?.sites) {
                    returnData = storageDataParsed?.sites;
                }
                break;
            case 'filter_client':
                if (storageDataParsed?.clients) {
                    returnData = storageDataParsed?.clients;
                }
                break;

            default:
                returnData = storageDataParsed;
                break;
        }
    }
    return returnData;
}

export const getLocalToken = (token_type) => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    if (!userToken || (userToken && !userToken.access)) {
        userToken = JSON.parse(tokenString);
    }
    return token_type == 'access' ? userToken?.access : userToken?.refresh;
}

export const clearCache = async (error) => {
    const refresh_token = getLocalToken('refresh');
    const data = error?.response?.data;
    if (error?.response?.status !== 500 && data?.code !== 'user_not_found') {
        api.post('jwt/token/refresh', { 'refresh': refresh_token })
            .then(res => {
                if (res?.data?.access) {
                    userToken = { refresh: refresh_token, access: res?.data?.access };
                    localStorage.setItem('token', JSON.stringify(userToken));
                    userToken = localStorage.setItem('token');
                    onFilterModel(false, true).then(res => {
                        reloadTheData(projectFilters, false, true);
                    });
                } else {
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    setTimeout(() => {
                        window.location.reload();
                    }, 100);
                }
            }).catch((error) => {
                if (error?.response?.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    setTimeout(() => {
                        window.location.reload();
                    }, 100);
                }
            });
    } else {
        if (data?.code == 'user_not_found') {
            window.location.href = '/login' + location.search;
        } else {
            jQuery('.loader-container').addClass('d-none');
        }
    }
}

export const compulsoryColumns = (columns, columnsInitialParam) => {
    var needed_columns = [];
    columns = columns.length > 0 && columns[0] !== '' ? columns : columnsInitialParam;
    if (columns) {
        for (var i in columns) {
            if (columns.hasOwnProperty(i)) {
                if (needed_columns.indexOf(columns[i]) < 0) {
                    needed_columns.push(columns[i]);
                }
            }
        }
    }
    return needed_columns;
}

export const getOptionsLength = (name) => {
    let tempStorageData = getLocalStorage('filter_data_main_table', name) ?? [];
    if (name == '[name="filter_site"]' && tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined') {
        return 1;
    }
    return $(name).find('option').length;
}

export const getSelectedLength = (name) => {
    let tempStorageData = getLocalStorage('filter_data_main_table', name) ?? [];
    // tempStorageData = isEmpty(tempStorageData) ? [] : tempStorageData;
    if (name == '[name="filter_site"]' && tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined') {
        return 1;
    }
    return tempStorageData.length ?? $(name).val()?.length;
}

export const getUser = () => {
    api.post('user/').then((user) => {
        localStorage.setItem('user', JSON.stringify(user.data));
        setTimeout(() => {
            window.location.href = '/' + location.search;
        }, 10);
        return user.data;
    });
}

export const getToken = () => {

    const tokenString = localStorage.getItem('token');
    if (tokenString && tokenString !== undefined && tokenString !== 'undefined') {
        const userToken = JSON.parse(tokenString);
        return userToken?.access
    }
    return '';

}

export async function setToken(userToken) {
    if (userToken && userToken !== undefined && userToken !== 'undefined') {
        localStorage.setItem('token', JSON.stringify(userToken));
        setTimeout(() => {
            window.location.href = '/' + location.search;
        }, 10);
    }
}

export const logout = () => {
    const refresh_token = getLocalToken('refresh');
    api.post('jwt/logout/', { 'refresh_token': refresh_token, 'all': 1 }).then(data => {
        clearStorage();
        setTimeout(() => {
            window.location.href = '/login' + location.search;
        }, 10);
    }).catch(error => {
        if (error?.response?.status === 401) {
            clearStorage();
            setTimeout(() => {
                window.location.href = '/login' + location.search;
            }, 10);
        }
    })
}

export function restoreRenderer(instance, td, row, col, prop, value, cellProperties) {
    var tempIndex = instance.getColHeader().findIndex(rows => rows == '_id');
    var tempIndexInstance = instance.getColHeader().findIndex(rows => rows == 'meta.instanceID');
    var tempData = instance.getData();
    var $button = $('<div class="p-2 text-center">');
    $button.html('<input type="checkbox" class="restore-log-row" data-id="' + tempData[row][tempIndex] + '" data-instance-id="' + tempData[row][tempIndexInstance] + '" />');
    $(td).empty().append($button); //empty is needed because you are rendering to an existing cell
}

export function commentsRenderer(instance, td, row, col, prop, value, cellProperties) {
    var tempIndex = instance.getColHeader().findIndex(rows => rows == 'meta.instanceID');
    var tempData = instance.getData();
    var $button = $('<div class="p-2 text-center" data-comments-submission-id="' + tempData[row][tempIndex] + '">');
    if (value > 0) {
        $button.html('<a href="javascript:;" class="add-comment-dialog text-decoration-none" data-id="' + tempData[row][tempIndex] + '">' + value + '</a>');
    } else {
        $button.html('<a href="javascript:;" class="add-comment-dialog text-decoration-none no-tasks" data-id="' + tempData[row][tempIndex] + '">Add</a>');
    }
    $(td).empty().append($button); //empty is needed because you are rendering to an existing cell
}

export function taskCommentsRenderer(instance, td, row, col, prop, value, cellProperties) {
    var tempIndex = instance.getColHeader().findIndex(rows => rows == 'meta.instanceID');
    var tempIdIndex = instance.getColHeader().findIndex(rows => rows == '_id');
    var tempData = instance.getData();
    var $button = $('<div class="p-2 text-center" data-comments-submission-id1="' + tempData[row][tempIndex] + '" data-task-id="' + tempData[row][tempIdIndex] + '">');
    if (value > 0) {
        $button.html('<a href="javascript:;" class="add-task-comment-dialog text-decoration-none" data-id="' + tempData[row][tempIndex] + '" data-task-id="' + tempData[row][tempIdIndex] + '">' + value + '</a>');
    } else {
        $button.html('<a href="javascript:;" class="add-task-comment-dialog text-decoration-none" data-id="' + tempData[row][tempIndex] + '" data-task-id="' + tempData[row][tempIdIndex] + '">Add</a>');
    }
    $(td).empty().append($button); //empty is needed because you are rendering to an existing cell
}

export const initRestoreEvents = () => {
    $('.restore-log-row').off('change');
    $('.restore-log-row').on('change', function () {
        var idArrayToRestore = getRestoreCount();
        $('.restoreData').prop('disabled', true);
        $('.restoreData').addClass('d-none');
        if (idArrayToRestore?.length > 0 && checkIfWriteAccess()) {
            $('.restoreData').prop('disabled', false);
            $('.restoreData').removeClass('d-none');
        }
    });

    $('.selectAllToRestore').off('click');
    $('.selectAllToRestore').on('click', function () {
        $('.restore-log-row').each(function (event, data) {
            if (!$(data).is(':checked')) {
                $(data).click();
            }
        });
    });

    $('.deSelectAllToRestore').off('click');
    $('.deSelectAllToRestore').on('click', function () {
        $('.restore-log-row').each(function (event, data) {
            if ($(data).is(':checked')) {
                $(data).click();
            }
        });
    });

    $('.restoreData').off('click');
    $('.restoreData').on('click', function () {
        restoreDataBase();
    })
}

export const getRestoreCount = () => {
    let idArrayToRestore = [];
    $('.restore-log-row').each(function (event, data) {
        if ($(data).is(':checked')) {
            idArrayToRestore.push($(data).data('id'));
        }
    });
    return idArrayToRestore;
}

export const restoreDataBase = () => {
    let currentDataToRestore = getRestoreCount();
    if (isEmpty(currentDataToRestore) == false) {
        jQuery('.modalL').show();
        api.post(`log/restore/`, JSON.stringify({ "ids": currentDataToRestore }))
            .then(res => {
                var response = res?.data;
                if (response == "ok" || (response?.success)) {
                    Notify("info", "The selected record(s) have been restored.");
                    $('.restoreData').prop('disabled', true);
                    $('.reload-log-data').click();
                    refreshTableData();
                }
                else {
                    Notify("error", "Error restoring the data");
                }
                $('.modalL').hide();
            }).catch(error => {
                $('.modalL').hide();
                Notify("error", "Server Error");
                clearCache(error);
            });
    }
}

function urlencode(str) {
    str = (str + '').toString();
    return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').
        replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
}


export const bindSummaryHtml = (resSiteSummary) => {
    var returnHtml = '';
    returnHtml += '<div class="d-flex" style="display: flex;justify-content: space-between;flex-wrap: wrap; min-width: 100%">';
    returnHtml += '<div class="col-md-6 float-left pull-left">';

    returnHtml += '<table class="table table-responsive table-striped w-full" style="min-width: 100%">';
    returnHtml += '<tbody style="min-width: 100%">';
    if (resSiteSummary?.earliest_submission_date) {
        returnHtml += '<tr class="w-full">';
        returnHtml += '<td>';
        returnHtml += '<b>Earliest Submission Date</b>';
        returnHtml += '</td>';
        returnHtml += '<td>';
        returnHtml += '<span>' + resSiteSummary?.earliest_submission_date + '</span>';
        returnHtml += '</td>';
        returnHtml += '</tr>';
    }

    if (resSiteSummary?.latest_submission_date) {
        returnHtml += '<tr class="w-full">';
        returnHtml += '<td>';
        returnHtml += '<b>Latest Submission Date</b>';
        returnHtml += '</td>';
        returnHtml += '<td>';
        returnHtml += '<span>' + resSiteSummary?.latest_submission_date + '</span>';
        returnHtml += '</td>';
        returnHtml += '</tr>';
    }

    if (resSiteSummary?.last_modified_submission_date) {
        returnHtml += '<tr class="w-full">';
        returnHtml += '<td>';
        returnHtml += '<b>Last Modified</b>';
        returnHtml += '</td>';
        returnHtml += '<td>';
        returnHtml += '<span>' + resSiteSummary?.last_modified_submission_date + '</span>';
        returnHtml += '</td>';
        returnHtml += '</tr>';
    }

    if (resSiteSummary?.total_submissions) {
        returnHtml += '<tr class="w-full">';
        returnHtml += '<td>';
        returnHtml += '<b>Number of Submissions</b>';
        returnHtml += '</td>';
        returnHtml += '<td>';
        returnHtml += '<span>' + resSiteSummary?.total_submissions + '</span>';
        returnHtml += '</td>';
        returnHtml += '</tr>';
    }

    if (resSiteSummary?.monitors) {
        returnHtml += '<tr class="w-full">';
        returnHtml += '<td>';
        returnHtml += '<b>Monitors</b>';
        returnHtml += '</td>';
        returnHtml += '<td>';
        returnHtml += '<span>' + resSiteSummary?.monitors?.join(', ') + '</span>';
        returnHtml += '</td>';
        returnHtml += '</tr>';
    }

    if (resSiteSummary?.lat && resSiteSummary?.long) {
        returnHtml += '<tr class="w-full">';
        returnHtml += '<td>';
        returnHtml += '<b>Coordinates</b>';
        returnHtml += '</td>';
        returnHtml += '<td>';
        returnHtml += '<span>' + resSiteSummary?.lat + ', ' + resSiteSummary?.long + '</span>';
        returnHtml += '</td>';
        returnHtml += '</tr>';
    }

    returnHtml += '</tbody>';
    returnHtml += '</table>';

    returnHtml += '</div>';

    returnHtml += '<div class="col-6 float-left pull-left">';

    // returnHtml += '<div class="d-flex flex-wrap justify-content-between">';
    returnHtml += '<div class="row max-h-10">';
    const images = resSiteSummary?.images;
    images?.map((rows, index) => {
        let matches = !rows.image || rows.image == '' ? 'https://placehold.co/150' : rows.image;
        matches = matches?.replace('https://datastaging.davidsengineering.com:8080/media/', 'https://datastaging.davidsengineering.com/static/media/');
        matches = matches?.replace('https://data.davidsengineering.com/media/', 'https://data.davidsengineering.com/static/media/');
        matches = matches?.replace('https://datastaging.davidsengineering.com:8080//media//', 'https://datastaging.davidsengineering.com/static/media/');
        matches = matches?.replace('https://data.davidsengineering.com//media//', 'https://data.davidsengineering.com/static/media/');
        returnHtml += '<div class="col-md-4 p-0 float-left pull-left img-container">';
        returnHtml += '<a href="' + matches + '" data-lightbox="image" data-title="Image-' + (index + 1) + '" data-alt="Image-' + (index + 1) + '">';
        // returnHtml += '<img class="img img-responsive img-thumbnail img-rounded" src="' + matches + '" alt="Image-'+ (index + 1) + '"/>';
        returnHtml += '<img class="w-100 p-1" src="' + matches + '" alt="Image-' + (index + 1) + '"/>';
        returnHtml += '<br />' + rows.datetime;
        returnHtml += '</a>';
        returnHtml += '</div>';
    })

    returnHtml += '</div>';

    returnHtml += '</div>';
    returnHtml += '</div>';
    return returnHtml;
}
export const disableSingleSite = (reload = true) => {
    let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
    if (tempStorageData.singleSiteMode) {
        tempStorageData.singleSiteMode = false;
        tempStorageData.singleSiteLat = '';
        tempStorageData.singleSiteLong = '';
        tempStorageData.singleSite = '';
        tempStorageData.singleSiteName = '';
        setLocalStorage(tempStorageData);
        setLocalStorage(false, 'isSingleSiteEnabled');
        let url = new URL(location.href);
        url.searchParams.delete('site');
        localStorage.removeItem('singleSiteData');
        if (reload) {
            window.location.href = url;
        }
    }
}

export const activateSingleSiteClick = () => {
    $('.activate-single-site').off('click');
    $('.activate-single-site').on('click', function () {
        let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
        let oldValue = tempStorageData.singleSite ?? '';
        let $_this = $(this);
        setTimeout(() => {
            if ($_this.parent().data('site') && $_this.parent().data('site-name')) {
                processSingleSite($_this, tempStorageData, $_this.parent().data('site'), $_this.parent().data('site-name'), oldValue, '');
            }
        }, 10);
    });

    $('.search-instance-id').off('click');
    $('.search-instance-id').on('click', function (e) {
        e.preventDefault();
        var $_this = $(this);
        var data_obj_id = $_this.data('instance-id');
        if (data_obj_id) {
            $('[name="submission_id"]').val(data_obj_id);
            const url = new URL(location);
            url.searchParams.set("tab", 0);
            history.pushState({}, "", url);
            $('.table-main-tab').click();
            setTimeout(() => {
                $('.apply-filter-btn').click();
            }, 10);
        }
    });
}

export const processSingleSiteFromMedia = (event, tab) => {
    let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
    let oldValue = tempStorageData.singleSite ?? '';
    var e = event?.target;
    var $_this = '';
    if (!e?.options) {
        $_this = event;
    } else {
        $_this = e.options[e.selectedIndex];
    }
    $_this = $($_this);
    api.post(`site/summary/`, { site: $_this.data('site')?.toString() }, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(resSiteSummary => {
        setLocalStorage(resSiteSummary?.data?.data, 'singleSiteData')
        processSingleSite($_this, tempStorageData, $_this.data('site'), $_this.data('site-name'), oldValue, tab);
    });
}

export const processSingleSite = ($_this, tempStorageData, site, siteName, oldValue, tab = 'media') => {
    var tempSitesObj = getLocalStorage('singleSiteData');
    tempStorageData.singleSiteMode = true;
    if (tempSitesObj?.lat) {
        tempStorageData.singleSiteLat = tempSitesObj?.lat;
    }
    if (tempSitesObj?.long) {
        tempStorageData.singleSiteLong = tempSitesObj?.long;
    }
    tempStorageData.singleSite = site;
    tempStorageData.singleSiteName = siteName;
    if ($_this.hasClass('table-tab')) {
        $('.table-main-tab').click();
        refreshTableData();
    }
    else if ($_this.hasClass('summary')) {

    }
    else if ($_this.hasClass('log')) {
        $('.log-main-tab').click();
        refreshTableData();
        setTimeout(() => {
            $('.reload-log-data').click();
        }, 10);
    } else if ($_this.hasClass('comments')) {
        $('.comments-main-tab').click();
        refreshTableData();
        setTimeout(() => {
            $('.reload-comments-data').click();
        }, 10);
    } else if ($_this.hasClass('media')) {
        $('.media-main-tab').click();
        setTimeout(() => {
            $('.reload-media-data').click();
        }, 10);
        refreshTableData();
    } else if ($_this.hasClass('graph')) {
        $('.graph-main-tab').click();
        setTimeout(() => {
            $('.refresh-graph-data').click();
        }, 10);
        refreshTableData();
    } else {
        if (tab == 'graph') {
            $('.graph-main-tab').click();
            setTimeout(() => {
                $('.refresh-graph-data').click();
            }, 10);
        } else if (tab == 'media') {
            $('.media-main-tab').click();
            setTimeout(() => {
                $('.reload-media-data').click();
            }, 10);
        } else if (tab == 'table') {
            $('.media-main-tab').click();
            setTimeout(() => {
                $('.reload-media-data').click();
            }, 10);
        } else if (tab == 'summary' || tab == 'map') {

        } else {
            $('.media-main-tab').click();
            setTimeout(() => {
                $('.reload-media-data').click();
            }, 10);
        }
        $('.refresh-table-data').click();
        refreshTableData();
    }
    // $('.select-site-container').removeClass('d-none');
    // $('.pagination-container-inner').addClass('d-none');
    if (tempStorageData.singleSite && oldValue != tempStorageData.singleSite) {
        setLocalStorage(tempStorageData);
        if (!$_this.hasClass('summary')) {
            $('.refresh-table-data').click();
            setTimeout(() => {
                if (tab == 'media' || tab == 'graph') {
                    // window.location.reload();
                }
            }, 1000);
        }
    }

}

export const refreshTableData = () => {
    setTimeout(() => {
        $('.refresh-map-table-data').click();
    }, 10);
}

export const clearStorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    clearStorageData();
}

export const clearStorageData = () => {
    localStorage.removeItem('filter_data_main_table');
    localStorage.removeItem('tableData');
    localStorage.removeItem('columnsObj');
    localStorage.removeItem('columnsDataTemp');
    localStorage.removeItem('singleSiteData');
    localStorage.removeItem('isSingleSiteEnabled');
}

export const checkIfValidUserTypes = (type, role = '') => {
    let USER_TYPES = [
        { id: 3, role: 'Client User' },
        { id: 4, role: 'Client Viewer' },
        { id: 1, role: 'Super Admin' },
        { id: 5, role: 'Project User' },
        { id: 6, role: 'Project Viewer' },
    ];

    if (USER_TYPES.find(rows => rows.id == type && rows.role == role)) {
        return true;
    }
    return false;
}

export const checkIfWriteAccess = () => {
    const userData = getLocalUser();
    if (checkIfValidUserTypes(userData?.user_type, 'Client User') || checkIfValidUserTypes(userData?.user_type, 'Project User') || checkIfValidUserTypes(userData?.user_type, 'Super Admin') || checkIfValidUserTypes(userData?.user_type, 'Admin')) {
        return true;
    }
    return false;
}

export const checkIfAdminAccess = () => {
    const userData = getLocalUser();
    if (checkIfValidUserTypes(userData?.user_type, 'Super Admin') || checkIfValidUserTypes(userData?.user_type, 'Admin')) {
        return true;
    }
    return false;
}

export const checkIfClientAccess = () => {
    const userData = getLocalUser();
    if (checkIfValidUserTypes(userData?.user_type, 'Client Viewer') || checkIfValidUserTypes(userData?.user_type, 'Client User') || checkIfValidUserTypes(userData?.user_type, 'Super Admin') || checkIfValidUserTypes(userData?.user_type, 'Admin')) {
        return true;
    }
    return false;
}

export const checkIfProjectAccess = () => {
    const userData = getLocalUser();
    if (checkIfValidUserTypes(userData?.user_type, 'Project Viewer') || checkIfValidUserTypes(userData?.user_type, 'Project User') || checkIfValidUserTypes(userData?.user_type, 'Super Admin') || checkIfValidUserTypes(userData?.user_type, 'Admin')) {
        return true;
    }
    return false;
}

export const getUrlVars = () => {
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

export const sortObject = (obj) => {
    obj.sort(function (a, b) {
        if (a.label?.toLowerCase() < b.label?.toLowerCase()) {
            return -1;
        }
        if (a.label?.toLowerCase() > b.label?.toLowerCase()) {
            return 1;
        }
        return 0;
    });
    return obj;
}

export const initRefreshGraphMediaAction = (selector, valueData) => {
    $(selector).on('click', function (e) {
        if (valueData == 4) {
            $('.reload-media-data').click();
        } else if (valueData == 5) {
            $('.refresh-graph-data').click();
        }
    });
}

export const getCurrentTimeZone = () => {
    let timeZone = $('[name="is_utc_only"]').is(":checked") ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZone;
}

export const getFilterFlag = (element_name) => {
    let filter_flag = 0;

    switch (element_name) {
        case 'filter_project':
            old_projects.sort();
            new_projects.sort();
            if (JSON.stringify(old_projects) !== JSON.stringify(new_projects)) {
                filter_flag = 1;
                let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
                tempStorageData.projects = new_projects;
                setLocalStorage(tempStorageData);
            }
            break;
        case 'filter_image_fields':
            old_image_fields.sort();
            new_image_fields.sort();
            if (JSON.stringify(old_image_fields) !== JSON.stringify(new_image_fields)) {
                filter_flag = 1;
                let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
                tempStorageData.image_fields = new_image_fields;
                setLocalStorage(tempStorageData);
            }
            break;
        case 'filter_image_fields':
            old_image_fields.sort();
            new_image_fields.sort();
            if (JSON.stringify(old_image_fields) !== JSON.stringify(new_image_fields)) {
                filter_flag = 1;
                let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
                tempStorageData.image_fields = new_image_fields;
                setLocalStorage(tempStorageData);
            }
            break;
        case 'filter_form_groups':
            old_formGroups.sort();
            new_formGroups.sort();
            if (JSON.stringify(old_formGroups) !== JSON.stringify(new_formGroups)) {
                let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
                tempStorageData.formGroups = new_formGroups;
                setLocalStorage(tempStorageData);
                filter_flag = 1;
            }
            break;
        case 'filter_form':
            old_forms.sort();
            new_forms.sort();
            if (JSON.stringify(old_forms) !== JSON.stringify(new_forms)) {
                let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
                tempStorageData.forms = new_forms;
                setLocalStorage(tempStorageData);
                filter_flag = 1;
            }
            break;
        case 'filter_client':
            old_clients.sort();
            new_clients.sort();
            if (JSON.stringify(old_clients) !== JSON.stringify(new_clients)) {
                let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
                tempStorageData.clients = new_clients;
                setLocalStorage(tempStorageData);
                filter_flag = 1;
            }
            break;
        case 'filter_site':
            old_sites.sort();
            new_sites.sort();
            let tempStorageData = getLocalStorage('filter_data_main_table', name) ?? [];
            if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined') {
                if (JSON.stringify(old_sites) !== JSON.stringify(new_sites)) {
                    let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
                    tempStorageData.sites = new_sites;
                    setLocalStorage(tempStorageData);
                    disableSingleSite(false);
                    filter_flag = 1;
                }
            } else {
                if (JSON.stringify(old_sites) !== JSON.stringify(new_sites)) {
                    let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
                    tempStorageData.sites = new_sites;
                    setLocalStorage(tempStorageData);
                    if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
                        disableSingleSite(false);
                    }
                    filter_flag = 1;
                }
            }
            break;
        case 'filter_monitor':
            old_monitors.sort();
            new_monitors.sort();
            if (JSON.stringify(old_monitors) !== JSON.stringify(new_monitors)) {
                let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
                tempStorageData.monitors = new_monitors;
                setLocalStorage(tempStorageData);
                filter_flag = 1;
            }
            break;

        case 'filter_qc_flag':
        case 'filter_qc_flags':
            old_qc_flags.sort();
            new_qc_flags.sort();
            if (JSON.stringify(old_qc_flags) !== JSON.stringify(new_qc_flags)) {
                let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
                tempStorageData.qcFlags = new_qc_flags;
                setLocalStorage(tempStorageData);
                filter_flag = 1;
            }
            break;

        default:
            break;
    }
    return filter_flag;
}

export const oldValuesLength = () => {
    let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
    old_projects_length = tempStorageData?.projects?.length ? tempStorageData?.projects?.length : $('[name="filter_project"]').find('option').length;
    old_image_fields_length = tempStorageData?.image_fields?.length ? tempStorageData?.image_fields?.length : $('[name="filter_image_fields"]').find('option').length;
    old_formGroups_length = tempStorageData?.formGroups?.length ? tempStorageData?.formGroups?.length : $('[name="filter_form_groups"]').find('option').length;
    old_forms_length = tempStorageData?.forms?.length ? tempStorageData?.forms?.length : $('[name="filter_form"]').find('option').length;
    old_clients_length = tempStorageData?.clients?.length ? tempStorageData?.clients?.length : $('[name="filter_client"]').find('option').length;
    old_sites_length = tempStorageData?.sites?.length ? tempStorageData?.sites?.length : $('[name="filter_site"]').find('option').length;
    old_monitors_length = tempStorageData?.monitors?.length ? tempStorageData?.monitors?.length : $('[name="filter_monitor"]').find('option').length;
    old_qc_flags_length = tempStorageData?.qcFlags?.length ? tempStorageData?.qcFlags?.length : $('[name="filter_qc_flag"]').find('option').length;
    old_commenter_length = tempStorageData?.commenter?.length ? tempStorageData?.commenter?.length : $('[name="filter_commenter"]').find('option').length;
    old_editor_length = tempStorageData?.editor?.length ? tempStorageData?.editor?.length : $('[name="filter_editor"]').find('option').length;
}

export const oldValues = () => {
    let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
    old_projects = tempStorageData?.projects?.length ? tempStorageData?.projects : $('[name="filter_project"]').val();
    old_image_fields = tempStorageData?.image_fields?.length ? tempStorageData?.image_fields : $('[name="filter_image_fields"]').val();
    old_formGroups = tempStorageData?.formGroups?.length ? tempStorageData?.formGroups : $('[name="filter_form_groups"]').val();
    old_forms = tempStorageData?.forms?.length ? tempStorageData?.forms : $('[name="filter_form"]').val();
    old_clients = tempStorageData?.clients?.length ? tempStorageData?.clients : $('[name="filter_client"]').val();
    old_sites = tempStorageData?.sites?.length ? tempStorageData?.sites : $('[name="filter_site"]').val();
    old_monitors = tempStorageData?.monitors?.length ? tempStorageData?.monitors : $('[name="filter_monitor"]').val();
    old_qc_flags = tempStorageData?.qcFlags?.length ? tempStorageData?.qcFlags : $('[name="filter_qc_flag"]').val();
}

export const newValues = () => {
    // let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
    let tempStorageData = {};
    new_projects = tempStorageData?.projects?.length ? tempStorageData?.projects : $('[name="filter_project"]').val();
    new_image_fields = tempStorageData?.image_fields?.length ? tempStorageData?.image_fields : $('[name="filter_image_fields"]').val();
    new_formGroups = tempStorageData?.formGroups?.length ? tempStorageData?.formGroups : $('[name="filter_form_groups"]').val();
    new_forms = tempStorageData?.forms?.length ? tempStorageData?.forms : $('[name="filter_form"]').val();
    new_clients = tempStorageData?.clients?.length ? tempStorageData?.clients : $('[name="filter_client"]').val();
    new_sites = tempStorageData?.sites?.length ? tempStorageData?.sites : $('[name="filter_site"]').val();
    new_monitors = tempStorageData?.monitors?.length ? tempStorageData?.monitors : $('[name="filter_monitor"]').val();
    new_qc_flags = tempStorageData?.qcFlags?.length ? tempStorageData?.qcFlags : $('[name="filter_qc_flag"]').val();
}

export const returnVariables = () => {
    return {
        old_clients_length: old_clients_length,
        old_qc_flags_length: old_qc_flags_length,
        old_projects_length: old_projects_length,
        old_image_fields_length: old_image_fields_length,
        old_formGroups_length: old_formGroups_length,
        old_forms_length: old_forms_length,
        old_sites_length: old_sites_length,
        old_monitors_length: old_monitors_length,
        old_commenter_length: old_commenter_length,
        old_editor_length: old_editor_length,
    }
}

export const postRequest = async (url, obj) => {
    return api.post(url, obj, {
        headers: { 'Content-Type': 'application/json', }
    });
}
export const getFilteredSiteData = (sites) => {
    return sites;
}


export const onlyUnique = (value, index, array) => {
    return array.indexOf(value) === index;
}

export const generateFilterCountAndAvailability = (columnData, fromComment = false, fromLog = false) => {
    let returnVariablesData = returnVariables();
    let getFromCookie = true;
    let tempStorageData = getLocalStorage('filter_data_main_table') ?? {};
    let totalFilters = 0;
    if ($('[name="submission_id"]').val() !== '') {
        totalFilters += 1;
    }

    if ($('[name="start_date"]').val() !== '') {
        totalFilters += 1;
    }

    if ($('[name="end_date"]').val() !== '') {
        totalFilters += 1;
    }

    if (tempStorageData?.projects?.length && getFromCookie) {
        totalFilters += 1;
    }

    if (tempStorageData?.clients?.length && getFromCookie) {
        totalFilters += 1;
    }

    if (tempStorageData?.formGroups?.length && getFromCookie) {
        totalFilters += 1;
    }

    if (tempStorageData?.forms?.length && getFromCookie) {
        totalFilters += 1;
    }

    if (tempStorageData?.singleSiteMode == true && tempStorageData?.singleSite != '' && tempStorageData?.singleSite != 'undefined' && getFromCookie && process.env.REACT_APP_SINGLE_SITE_MODE == 1) {
        totalFilters += 1;
    }
    else if (tempStorageData?.sites?.length && getFromCookie) {
        totalFilters += 1;
    }
    else if (returnVariablesData.old_sites_length != getSelectedLength('[name="filter_site"]') && $('[name="filter_site"]').val()?.length && !getFromCookie) {
        totalFilters += 1;
    }

    if (tempStorageData?.monitors?.length && getFromCookie) {
        totalFilters += 1;
    }

    if (getOptionsLength('[name="filter_param"]') != getSelectedLength('[name="filter_param"]') && $('[name="filter_param"]').val()?.length) {
        totalFilters += 1;
    }

    if (fromComment) {
        if (tempStorageData?.commenter?.length && getFromCookie) {
            totalFilters += 1;
        }
    }

    if (fromLog) {
        if (tempStorageData?.editor?.length && getFromCookie) {
            totalFilters += 1;
        }
    }

    return { hasFilter: totalFilters > 0 ? true : false, totalFilters: totalFilters };
}